import { Col, Row } from 'antd'
import StatusCard from '../components/StatusCard'
import CateCard from '../components/CateCard'

function SuperAdminPage({ data, channel }) {
  const statusCardData = data ? { file_count: data.file_count, duration_count: data.duration_count } : {file_count:0,duration_count:0, detection: [], scenario_detection: []}

  return (
    <Row gutter={[20, 10]} style={{ flex: 1, marginBottom: '10px' }}>
      {(channel !== 'All') ?
        <>
          <Col span={24}>
            <StatusCard sidePrefix="Team" view={'Admin'} data={data.team} />
          </Col>
        </>
        :
        <>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <StatusCard sidePrefix="Super" view={'SuperAdmin'} data={statusCardData}/>
          </Col>
          <Col xs={24} sm={12} md={8} lg={9} xl={9}>
            <CateCard name={"Monthly Usage (Files)"} view={'SuperAdmin'} data={data ? data.file_usage : null}/>
          </Col>
          <Col xs={24} sm={12} md={8} lg={9} xl={9}>
            <CateCard name={"Monthly Usage (Duration)"} view={'SuperAdmin'} data={data ? data.duration_usage:null}/>
          </Col>
        </>
      }
    </Row>
  )
}

export default SuperAdminPage