import { createContext, useContext} from "react";
import { useNavigate } from "react-router-dom";
import decode from 'jwt-decode'
import CryptoJS from "crypto-js"
import useNotification from "../../components/Notification";
import moment from "moment";



const AuthContext = createContext();

export const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useNotification();
  const encryptionKey = 'ai9-memo';
  var user = JSON.parse(localStorage.getItem("stt"));
  if (user != null) {
    const decodeToken = decode(user.accessToken)
    let date = moment().unix(new Date().valueOf())

    if (date > decodeToken.exp) {
      localStorage.clear();
      window.location.href=process.env.REACT_APP_LANDING_PAGE
    } else {
      user = JSON.parse(localStorage.getItem("stt"));
    }
  }

  const login = async (data) => {
    //รับค่าจากbackendมาเพื่อเก็บไว้ในlocal
    // const result = {
    //   accessToken: data.token,
    //   ...data.user_profile,
    // };
    // localStorage.setItem("stt", JSON.stringify(result));
    // navigate("/task");
    // if (data.user_profile.role == "User") {
    //   navigate("/task");
    // } else {
    //   navigate("/");
    // }
    
    //test dev
    const result = {
      accessToken: data.token,
      ...data,
    };

    const encryptHash = CryptoJS.AES.encrypt(JSON.stringify(result), encryptionKey).toString();

    localStorage.setItem("stt", JSON.stringify(result));
    localStorage.setItem("tss", encryptHash);

    if (data.role === "Member") {
      navigate("/video");
      openNotification("success", "Login Success");
    } else {
      navigate("/dashboard");
      openNotification("success", "Login Success");
    }

  };


  const logout = () => {
    localStorage.clear();
    window.location.href=process.env.REACT_APP_LANDING_PAGE
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {contextHolder}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
