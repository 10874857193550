import config from "../config/config";
import { getToken } from "../utils/prefix";

export async function tesk_createAPI(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
export async function getTagList() {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/tag/get-by-org`, {
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
export async function getTag(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/tag/get`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
export async function getTagFromList(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/tag/get-by-names`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
export async function addTag(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/tag/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
export async function getDashBoard(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/task/getdashboard`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}
export async function getDetection(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/task/getdetection`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function updateTask(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/updatevideo`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function getTaskID(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/gettaskid`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function resend_updateTask(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/update`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_drawCanvas(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/drawcanvas`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_createDashboard(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/dashboard/add`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_getDashboardID(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/dashboard/getbyid`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_getCountModels(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/get-task`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_getDataModels(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/task/get-data-task`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_advancedSearch(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/task/advancedsearch`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_simpleSearch(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/task/simplesearch`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_getEvaluate(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/evaluate`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function task_addEvaluate(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/task/addevaluate`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
