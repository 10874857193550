import { Col, Row } from "antd"
import StatusCard from "../components/StatusCard"
import "../style/StatusCard.less"

function AdminPage(data) {
  return (
    <Row className="admin-row" gutter={[20,20]} style={{ flex: 1, marginBottom: '10px'}}>
      <Col xs={24} sm={24} md={24} lg={9} xl={9}>
        <StatusCard sidePrefix="Your" view={'Admin'} data={data.data ? data.data.member : null} />
      </Col>
      <Col xs={24} sm={24} md={24} lg={15} xl={15}>
        <StatusCard sidePrefix="Team" view={'Admin'} data={data.data ? data.data.team : null} />
      </Col>
    </Row>
  )
}

export default AdminPage