import { useState } from "react";
import { notification } from "antd";
import "./style/index.less";
type NotificationType = "success" | "info" | "warning" | "error";

const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (
    type: NotificationType,
    message: string,
    description: string
  ) => {
    api[type]({
      message: message,
      description: <>{description ? description : false}</>,
      placement: "topRight",
      closeIcon: true,
      className: "notification",
      duration:1
    });
  };

  return {
    openNotification,
    contextHolder,
  };
};

export default useNotification;
