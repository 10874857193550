import { Button, Card, Col, Form, Input, List, Modal, Row, Tag } from "antd";
import "./styles/index.less";
import useNotification from "../../../components/Notification";
import React, { useEffect, useState } from "react";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  createModel,
  deleteModel,
  editModel,
  selectedAllModel,
  selectedModel,
} from "../../../services/model";
import { useRecoilState } from "recoil";
import { localeState } from "../../../utils/atoms";
import moment from "moment";
import { getBreakpoint } from "../../../utils/libs/breakdown";
import { t } from "i18next";

const { confirm } = Modal;

type Props = {};

const Model = (props: Props) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const [form] = Form.useForm();
  const [topic, setTopic] = useState<any>([]);
  const [object, setObject] = useState<any>([]);
  const [scene, setScene] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [modalDetail, setModalDetail] = useState<any>({
    status: "create",
    topic: "text",
    id: null,
  });
  const { openNotification, contextHolder } = useNotification();

  const [modelType, setModelType] = useState<any>({
    text: "b875162b-0492-11ee-a8f8-0242ac1b0005",
    obj: "b875130b-0492-11ee-a8f8-0242ac1b0005",
    scene: "b8750eec-0492-11ee-a8f8-0242ac1b0005",
  });
  const screen = getBreakpoint();
  const test: any = screen();
  // for (var key in test) {
  //   if (test.hasOwnProperty(key) && test[key] == false) {
  //     delete test[key];
  //   }
  // }
  Object.keys(test).filter((item) => {
    if (test[item] == false) {
      delete test[item];
    }
  });
  console.log("screen:", test);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let text: any = [];
      let object: any = [];
      let scene: any = [];
      const res = await selectedAllModel();

      if (res.status == 200) {
        console.log("res", res.data);
        res.data.map((d: any) => {
          if (d.model == "text") {
            text.push(d);
          } else if (d.model == "object") {
            object.push(d);
          } else if (d.model == "scene") {
            scene.push(d);
          }
        });
        sortData(text);
        sortData(object);
        sortData(scene);

        setTopic(text);
        setObject(object);
        setScene(scene);
      } else {
        openNotification("error", "Error", "Cannot get a model data !");
      }
    } catch (e) {
      openNotification("error", "Error", "Cannot get a model data !");
      console.error(e);
    }
  };

  const sortData = async (data: any) => {
    return data.sort(
      (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix()
    );
  };

  const handleOpenModel = async () => {
    setIsModalOpen(true);
  };

  const handleCreateForm = async (values: any) => {
    try {
      console.log("values create::", values, modalDetail);
      setLoading(true);
      const result = {
        data: values.name,
        model_type:
          modalDetail.topic == "text"
            ? modelType.text
            : modalDetail.topic == "object"
            ? modelType.obj
            : modelType.scene,
        model_name: values.model,
      };
      const res = await createModel(result);
      if (res.message == "success") {
        await new Promise((resolve) => setTimeout(resolve, 500));
        setLoading(false);
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const handleEditForm = async (values: any) => {
    try {
      console.log("values edit::", values, modalDetail);
      const res = await editModel({
        id: modalDetail.id,
        text: values.name,
        model:
          modalDetail.topic == "text"
            ? modelType.text
            : modalDetail.topic == "object"
            ? modelType.obj
            : modelType.scene,
        model_name: values.model,
      });
      if (res.message == "success") {
        getData();
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteModel = async (values: any) => {
    confirm({
      title: "Are you sure delete this topic?",
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: async () => {
        try {
          const res = await deleteModel({ id: values });
          if (res.message == "Success") {
            getData();
          }
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div className="content-container">
      {contextHolder}
      <Row>
        <h1 className="header-topic">{t("Model")}</h1>
      </Row>
      <Row
        gutter={[
          { xs: 12, sm: 12, md: 2, xl: 16 },
          { xs: 12, sm: 12, md: 2, xl: 16 },
        ]}
        justify="space-around"
        className="card-model"
        style={
          {
            // border: "solid red 1px",
            // width: "650px",
            // overflowX: "scroll",
            // width: "100%",
          }
        }
        // justify={"start"}
        // wrap={false}
      >
        <Col xs={24} sm={24} md={7}>
          {/* <Col> */}
          <Card
            title={t("Text")}
            bordered={false}
            // style={{ background: "white" }}
            // style={{ width: "350px", marginLeft: "10px", marginRight: "10px" }}
            className="card-container-model"
            actions={[
              <Button
                type="dashed"
                size="middle"
                style={{
                  borderColor: "#E04D8B",
                  color: "#E04D8B",
                  width: "95%",
                  fontSize: "13px",
                }}
                className="text-model"
                onClick={() => {
                  setModalDetail({
                    status: "create",
                    topic: "text",
                    id: null,
                  });
                  handleOpenModel();
                }}
              >
                <PlusOutlined /> {t("Add Topic")}
              </Button>,
            ]}
          >
            <div id="scrollableDiv" className="scrollModel">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={topic}
                style={{ height: "230px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    {/* <div className="card-text">
                      <div className="left-model">
                        <Tag className="modelTag" color="magenta">
                          {item.text}
                        </Tag>
                      </div>

                      <div className="right-model">
                        <Row gutter={16}>
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              console.log("name", item);
                              setModalDetail({
                                status: "edit",
                                topic: "text",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled />
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled />
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                    <Row gutter={12}>
                      <Col xs={18} sm={18} md={20}>
                        <Tag className="model_tag" color="magenta">
                          <Row
                            justify="center"
                            align="middle"
                            style={{ height: "100%",cursor:"default" }}
                          >
                            {item.text}
                          </Row>
                        </Tag>
                      </Col>
                      {/* button */}
                      <Col xs={6} sm={6} md={4}>
                        <Row gutter={12} justify="center">
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              console.log("name", item);
                              setModalDetail({
                                status: "edit",
                                topic: "text",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled className="edit-model"/>
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled className="delete-model"/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={7}>
          {/* <Col> */}
          <Card
            title={t("Object")}
            bordered={false}
            // style={{ width: "350px", marginLeft: "10px", marginRight: "10px" }}
            className="card-container-model"
            actions={[
              <Button
                type="dashed"
                size="middle"
                style={{
                  borderColor: "#218AE7",
                  color: "#218AE7",
                  width: "95%",
                  fontSize: "13px",
                }}
                className="obj-model"
                onClick={() => {
                  setModalDetail({
                    status: "create",
                    topic: "object",
                    id: null,
                  });
                  handleOpenModel();
                }}
              >
                <PlusOutlined /> {t("Add Object")}
              </Button>,
            ]}
          >
            <div id="scrollableDiv" className="scrollModel">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={object}
                style={{ maxHeight: "230px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    {/* <div className="card-text">
                      <div className="left-model">
                        <Tag color="blue" className="modelTag">
                          {item.text}
                        </Tag>
                      </div>
                      <div className="right-model">
                        <Row gutter={16}>
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              setModalDetail({
                                status: "edit",
                                topic: "object",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled />
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled />
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                    <Row gutter={12}>
                      <Col xs={18} sm={18} md={20}>
                        <Tag className="model_tag" color="blue">
                          <Row
                            justify="center"
                            align="middle"
                            style={{ height: "100%",cursor:"default" }}
                          >
                            {item.text}
                          </Row>
                        </Tag>
                      </Col>
                      {/* button */}
                      <Col xs={6} sm={6} md={4}>
                        <Row gutter={12} justify="center">
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              console.log("name", item);
                              setModalDetail({
                                status: "edit",
                                topic: "text",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled className="edit-model"/>
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled className="delete-model"/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={7}>
          {/* <Col> */}
          <Card
            title={t("Scene")}
            bordered={false}
            // style={{ width: "350px", marginLeft: "10px", marginRight: "10px" }}
            className="card-container-model"
            actions={[
              <Button
                type="dashed"
                size="middle"
                style={{
                  borderColor: "#2EA66C",
                  color: "#2EA66C",
                  width: "95%",
                  fontSize: "13px",
                }}
                className="scene-model"
                onClick={() => {
                  setModalDetail({
                    status: "create",
                    topic: "scene",
                    id: null,
                  });
                  handleOpenModel();
                }}
              >
                <PlusOutlined /> {t("Add Scene")}
              </Button>,
            ]}
          >
            <div id="scrollableDiv" className="scrollModel">
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={scene}
                style={{ height: "230px" }}
                renderItem={(item: any, i) => (
                  <List.Item>
                    {/* <div className="card-text">
                      <div className="left-model">
                        <Tag color="green" className="modelTag">
                          {item.text}
                        </Tag>
                      </div>
                      <div className="right-model">
                        <Row gutter={16}>
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              setModalDetail({
                                status: "edit",
                                topic: "scene",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled />
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled />
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                    <Row gutter={12}>
                      <Col xs={18} md={18} lg={20}>
                        <Tag className="model_tag" color="green">
                          <Row
                            justify="center"
                            align="middle"
                            style={{ height: "100%",cursor:"default" }}
                          >
                            {item.text}
                          </Row>
                        </Tag>
                      </Col>
                      {/* button */}
                      <Col xs={6} md={6} lg={4}>
                        <Row gutter={12} justify="center">
                          <Col
                            className="model-action"
                            onClick={() => {
                              form.setFieldsValue({
                                name: item.text,
                                model: item.model_name,
                              });
                              console.log("name", item);
                              setModalDetail({
                                status: "edit",
                                topic: "text",
                                id: item.model_id,
                              });
                              handleOpenModel();
                            }}
                          >
                            <EditFilled className="edit-model"/>
                          </Col>
                          <Col
                            className="model-action"
                            onClick={() => {
                              handleDeleteModel(item.model_id);
                            }}
                          >
                            <DeleteFilled className="delete-model"/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>

      {/* modal */}
      <Modal
        title={
          modalDetail.topic == "text"
            ? t("Text")
            : modalDetail.topic == "object"
            ? t("Object")
            : t("Scene")
        }
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          setModalDetail({
            status: "create",
            topic: "text",
            id: null,
          });
          setIsModalOpen(false);
        }}
        closable={false}
        // maskClosable={false}
        width={420}
        footer={[
          <Button
            onClick={() => {
              form.resetFields();
              setModalDetail({
                status: "create",
                topic: "text",
                id: null,
              });
              setIsModalOpen(false);
            }}
          >
            {t("Cancel")}
          </Button>,
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            {t("Add")}
          </Button>,
        ]}
        // centered
      >
        <Form
          form={form}
          onFinish={(values) => {
            modalDetail.status == "create"
              ? handleCreateForm(values)
              : handleEditForm(values);
          }}
        >
          <Row justify="center">
            <Col xs={20}>
              <Form.Item
                name="name"
                label={t("Name")}
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input
                  placeholder={
                    modalDetail.topic == "text"
                      ? t("Topic")
                      : modalDetail.topic == "object"
                      ? t("Object")
                      : modalDetail.topic == "scene"
                      ? t("Scene")
                      : ""
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={20}>
              <Form.Item
                name="model"
                label={t("Model")}
                rules={[
                  { required: true, message: "Please input your model!" },
                ]}
              >
                <Input placeholder={t("Model")} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Model;
