import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Space,
  DatePicker,
  Select,
  Spin
} from "antd";
import StackedChart from "./components/StackedChart";
import "./style/index.less";
import { useAuth } from "../../utils/Auth/AuthWrapper";
import UserPage from './page/UserPage'
import AdminPage from "./page/AdminPage";
import SuperAdminPage from "./page/SuperAdminPage"
import moment from "moment";
import dayjs from "dayjs";
import { useSocket } from "../../utils/socketContext/socketProvider";
import { useRecoilState } from "recoil";
import { localeState } from "../../utils/atoms";
import { translate } from "../../utils/libs/translate";
import { getDashBoard, getDetection } from "../../services/task";
import { org_getAll } from "../../services/organization";

dayjs.locale("en")

const Dashboard = () => {
  const date = moment(new Date() - 1).format("YYYY-MM")
  const [locale, setLocale] = useRecoilState(localeState);
  const [socketUpdate, setSocketUpdate] = useState(false)
  const [options, setOptions] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [stackData, setStackData] = useState(null)
  const [dateState, setDateState] = useState(date)
  const [loading, setLoading] = useState(false)
  const [channel, setChannel] = useState("All")
  const today = moment().format("DD MMMM YYYY")
  const { socket } = useSocket();

  const { user } = useAuth();
  const { role, org_id } = user

  const monthYearFormat = "MMM-YYYY"
  dayjs.locale("th")


  //get org list options and join socket room
  useEffect(() => {
    const data = {
      page: 1,
      size: 100,
      order_by: [],
      query: "",
      exportData: false,
    };
    const getOptions = async () => {
      try {
        const res = await org_getAll(data)
        const optionList = res.result.map((item) => {
          return { label: item.name, value: item.org_id }
        })
        optionList.unshift({ label: "All", value: "All" })
        setOptions(optionList)
      } catch (error) {
        setOptions(null)
      }
    }
    if (role === "SuperAdmin") {
      getOptions()
    }
    socket.emit("join_room", role === "SuperAdmin" ? "super" : org_id)
  }, [])

  //get dashboard data function
  const getDashBoardData = async () => {
    setLoading(true)
    try {
      let [year, month] = dateState.split("-")
      let res = await getDashBoard({ org: channel, month: month, year: year })
      if (res.status === 200 || res.data.length !== 0) {
        setChartData(res.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  //get daily detection usage function
  const getDetectionData = async () => {
    setLoading(true)
    try {
      let res = await getDetection({ org: channel });
      console.log(res);
      if (res.status === 200 || res.data.length !== 0) {
        setStackData(res.data)
        //   setStackData([{
        //     "name":"",
        //     "detected": 10,
        //     "undetected":5
        // }])

      } else {
        console.log("no data");
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  //get data when date or chanel change
  useEffect(() => {
    getDashBoardData()
  }, [dateState, channel])

  useEffect(() => {
    getDetectionData()
  }, [channel])

  //socket listener
  useEffect(() => {
    socket.on("update_dashboard", (data) => {
      setSocketUpdate(prev => prev = true)
    })
    return () => {
      socket.off("update_dashboard")
    }
  }, [socket])

  //update data when socket update
  useEffect(() => {
    if (socketUpdate) {
      getDetectionData()
      getDashBoardData()
      setSocketUpdate(prev => prev = false)
    }
  }, [socketUpdate])

  //!Function
  const handleDatePickerChange = (date, dateString) => {
    const selectedDate = dayjs(date).format("YYYY-MM")
    setDateState(selectedDate)
  };

  const selectChannelChange = (value) => {
    setChannel(value)
  }

  const setAll = () => {
    setChannel("All")
  }

  const onClear = () => {
    setAll()
  }

  return (
    <>
      <div className="content-container">
        <div className="dashboard-menu">
          <div className="dashboard-menu-layout">
            <h1 className="header-topic" style={{ margin: 0 }}>{translate("Dashboard")}</h1>
            {/* dropdown menu for admin */}
            {(role === 'SuperAdmin') &&
              <Select
                showSearch
                value={channel}
                defaultValue={"All"}
                className={"select-channel"}
                onClear={onClear}
                onChange={selectChannelChange}
                options={options}
              />
            }
          </div>
          <div>
            <DatePicker
              defaultValue={dayjs()}
              format={monthYearFormat}
              onChange={handleDatePickerChange}
              picker={"month"}
            />
          </div>
        </div>

        {/* status card and pie chart */}
        <Spin spinning={loading}>
          {(role === 'User' && <UserPage data={chartData} />)}
          {(role === 'Admin' && <AdminPage data={chartData} />)}
          {(role === 'SuperAdmin' && <SuperAdminPage data={chartData} channel={channel} />)}
          {/* stacked chart card */}
          <Row>
            <Col span={24}>
              <Card className="w-full">
                <Space direction="vertical" className="w-full">
                  {role === "SuperAdmin" && channel === "All" ?
                    <>
                      {console.log("data check1 ::", stackData)}
                      <h1 style={{ fontWeight: 600 }}>
                        Today System Usage ({today})
                      </h1>
                      <StackedChart data={stackData} view={role} />
                    </>
                    :
                    <>
                      {console.log("data check ::", stackData)}
                      <h1 style={{ fontWeight: 600 }}>
                        {`Team Daily Activity ( ${today} )`}
                      </h1>
                      <StackedChart data={stackData} view={"Admin"} />
                    </>
                  }
                </Space>
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );

};

export default Dashboard;

