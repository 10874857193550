import { useEffect, useState } from "react";
import "./styles/index.less";
import {
  Card,
  Col,
  Image,
  Pagination,
  Row,
  Input,
  Space,
  Spin,
  Select,
  Empty,
  Form,
  Dropdown,
  Modal,
} from "antd";
import useNotification from "../../../components/Notification";
import NoImage from "../../../assets/images/logoMemo.jpg";
import { useNavigate } from "react-router-dom";
import { api_getAllVideo } from "../../../services/video";
import moment from "moment";
// import TagList, { Container } from "../../videos/components/TagList";
import TagList, { Container } from "../../../components/TagList";
import ContainerDimensions from "react-container-dimensions";
import { translate } from "../../../utils/libs/translate";
import config from "../../../config/config";
// import useNotification from "../../../components/Notification";
import { useRecoilState } from "recoil";
import { localeState } from "../../../utils/atoms";
import { t } from "i18next";
import {
  EyeOutlined,
  MoreOutlined,
  SettingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
moment.updateLocale("th", {
  day: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    ,
    "Thursday",
    "Friday",
    "Saturday",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Today at] LT",
    lastWeek: "[Last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "dddd DD [at] LT",
  },
});

type Props = {};

const AllVideoPage = (props: Props) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(8);
  const { openNotification, contextHolder } = useNotification();
  const [form] = Form.useForm();
  // const [filter, setFilter] = useState<any>(null);
  // const [sort, setSort] = useState<any>(null);
  // let filter: any = null;
  const [sorts, setSorts] = useState("created_timestamp");
  // let sort: any = null;
  // let order: any = "desc";
  const [open, setOpen] = useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const form_sort = form.getFieldValue("sort");
      const form_filter = form.getFieldValue("filter");
      const form_order = form.getFieldValue("order");

      const dataInfo = {
        page: currentPage,
        size: pageSize,
        filter: form_filter != undefined ? form_filter : null,
        // order_by: [sort ? sort + ` ${order}` : `created_timestamp ${order}`],
        order_by:
          form_sort == undefined && form_order == undefined
            ? [`created_timestamp desc`]
            : [
                `${form_sort == undefined ? "created_timestamp" : form_sort} ${
                  form_order == undefined ? "asc" : form_order
                }`,
              ],
        query: searchValue,
        exportData: false,
      };
      const res = await api_getAllVideo(dataInfo);
      if (res.success === true) {
        setData(res.data);
        setTotal(res.meta.total);
        if (res.data == null || res.data.length === 0) {
          setCurrentPage(1);
        }
        delayLoading();
      } else {
        delayLoading();
        openNotification("error", "Cannot get videos", "");
      }
    } catch (e) {
      delayLoading();
      openNotification("error", "Cannot get videos", "");
      console.error(e);
    }
  };

  const delayLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onSearch = (value: string) => {
    setCurrentPage(1);
    setSearchValue(value);
  };

  const onSelect = (value: number) => {
    setPageSize(value);
  };

  const items: MenuProps["items"] = [
    {
      label: "setting",
      key: "1",
      icon: <SettingOutlined />,
      onClick: ({ item, key, keyPath, domEvent }) => {
        console.log("item", item);
        console.log("key", key);

        console.log("keyPath", keyPath);

        console.log("domEvent", domEvent);

        setOpen(true);
      },
    },
    {
      label: "delete",
      key: "2",
      icon: <DeleteOutlined />,
    },
  ];

  const menuFil1 = [
    {
      value: "desc",
      label: "Newest to Oldest",
    },
    {
      value: "asc",
      label: "Oldest to Newest",
    },
  ];

  const menuFil2 = [
    {
      value: "asc",
      label: "A -> Z",
    },
    {
      value: "desc",
      label: "Z -> A",
    },
  ];

  const menuFil3 = [
    {
      value: "asc",
      label: "shortest length",
    },
    {
      value: "desc",
      label: "longest length",
    },
  ];

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      console.log("This will run every second!");
      getData();
      // }, 3000);
    }, 300000);
    return () => clearInterval(interval);
  }, [currentPage, searchValue, pageSize]);

  return (
    <div className="content-container" style={{ height: "100%" }}>
      {contextHolder}
      <Row
        wrap={true}
        gutter={[10, 10]}
        style={{ justifyContent: "space-between" }}
      >
        <Col>
          <h1 className="header-topic">{t("All Video")}</h1>
        </Col>
        <Col style={{ marginRight: "10px" }}>
          <Row>
            <Col>
              <Space wrap>
                <Form layout="inline" style={{ maxWidth: "none" }} form={form}>
                  <Form.Item label="Filter By" name="filter">
                    <Select
                      className="filter-by"
                      defaultValue="All"
                      style={{ width: 140 }}
                      onSelect={(value) => {
                        // setFilter(value);
                        // filter = value;
                        getData();
                      }}
                      // bordered={false}
                      options={[
                        { value: null, label: "All" },
                        { value: 4, label: "Processing" },
                        { value: 5, label: "Ready To View" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label="Sort By" name="sort">
                    <Select
                      className="filter-by"
                      defaultValue="created_timestamp"
                      style={{ width: 120 }}
                      onSelect={(value) => {
                        // setSort(value);
                        // sort = value;
                        getData();
                      }}
                      onChange={(value) => {
                        form.setFieldsValue({
                          order:
                            value == "title" || value == "duration"
                              ? "asc"
                              : "desc",
                        });
                        setSorts(value);
                      }}
                      // bordered={false}
                      options={[
                        {
                          value: "created_timestamp",
                          label: "Date",
                        },
                        { value: "title", label: "Name" },
                        {
                          value: "duration",
                          label: "Length",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item label="Order By" name="order">
                    <Select
                      className="filter-by"
                      defaultValue={form.getFieldValue("order") || "desc"}
                      style={{ width: 160 }}
                      onSelect={(value) => {
                        // setSort(value);
                        // order = value;
                        getData();
                      }}
                      // bordered={false}
                      options={
                        sorts == "created_timestamp"
                          ? menuFil1
                          : sorts == "title"
                          ? menuFil2
                          : sorts == "duration"
                          ? menuFil3
                          : []
                      }
                    />
                  </Form.Item>
                </Form>
              </Space>
            </Col>
            <Col>
              <Input.Search
                className="input-search"
                placeholder={translate("Search")}
                onSearch={onSearch}
                enterButton
                allowClear
                style={{ marginBottom: "0.5em" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ width: "100%", justifyContent: "space-around" }}>
        {isLoading ? (
          <div
            style={{
              width: "100vw",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          // <Spin spinning={isLoading} style={{ width: "100%" }}>
          <Row
            gutter={[32, 32]}
            style={{ width: "100%", marginTop: "1rem" }}
            justify={"start"}
          >
            {
              data &&
                data.map((d: any) => {
                  return (
                    <Col
                      key={d.task_id}
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 8 }}
                      lg={{ span: 6 }}
                      xl={{ span: 6 }}
                      xxl={{ span: 6 }}
                    >
                      <Card
                        cover={
                          <div
                            style={{
                              height: "170px",
                              minHeight: "170px",
                              width: "100%",
                              position: "relative",
                            }}
                          >
                            <Image
                              preview={false}
                              style={{
                                objectFit: "contain",
                                cursor: "pointer",
                                borderBottom: "1px solid #ccc",
                              }}
                              alt="video-profile"
                              height={170}
                              width={"100%"}
                              src={
                                d.image_path != ""
                                  ? `${process.env.REACT_APP_IMG_PATH}${d.image_path}`
                                  : NoImage
                              }
                              onClick={() => {
                                if (d.status == 5) {
                                  navigate(`/video/${d.task_id}`);
                                }else{
                                  openNotification("warning", "Video is not ready", "")
                                }
                              }}
                            />
                            <div
                              className="status_video"
                              style={
                                d.status == 5
                                  ? {
                                      backgroundColor: "rgb(94, 194, 0,0.8)",
                                      color: "white",
                                    }
                                  : d.status == 4
                                  ? {
                                      backgroundColor: "#f5bf42",
                                      color: "white",
                                    }
                                  : {
                                      backgroundColor: "#f5bf42",
                                      // backgroundColor:
                                      //   "rgb(122, 122, 122,0.8)",
                                      color: "white",
                                    }
                              }
                            >
                              {d.status == 5
                                ? "Ready To View"
                                : d.status == 4
                                ? // ? "Processing 0%"
                                  "Processing 20%"
                                : "Processing 0%"}
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                bottom: 5,
                                right: "0",
                                backgroundColor: "rgb(0 0 0/0.6)",
                                color: "white",
                                padding: "4px 6px",
                              }}
                            >
                              {new Date(d.duration * 1000)
                                .toISOString()
                                .slice(11, 19)}
                            </div>
                          </div>
                        }
                        bordered={false}
                      >
                        <Row gutter={[8, 8]}>
                          <Col xs={22}>{d.title}</Col>
                          <Col xs={2}>
                            <Dropdown
                              menu={{
                                items,
                                onClick: () => console.log("ddd", d),
                              }}
                              placement="bottomRight"
                              trigger={["click"]}
                            >
                              <a
                                onClick={(e) => e.preventDefault()}
                                style={{ float: "right", color: "black" }}
                              >
                                <Space>
                                  <MoreOutlined
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Space>
                              </a>
                            </Dropdown>
                          </Col>
                          <Col
                            xs={24}
                            style={{
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            <Container>
                              <ContainerDimensions>
                                {({ width }) => (
                                  <TagList
                                    parentWidth={width}
                                    tags={d.tag}
                                    setSearchValue={setSearchValue}
                                  />
                                )}
                              </ContainerDimensions>
                            </Container>
                          </Col>
                          <Col xs={24} style={{ opacity: 0.8 }}>
                            {d.name}
                          </Col>
                          <Col xs={20} style={{ opacity: 0.8 }}>
                            {moment(d.created_timestamp).fromNow()}
                          </Col>
                          <Col xs={4}>
                            <a
                              style={{ float: "right" }}
                              onClick={() => {
                                if (d.status == 5) {
                                  navigate(`/video/${d.task_id}`);
                                } else {
                                  openNotification("warning", "Video is not ready", "")
                                }
                              }}
                            >
                              <Space>
                                <EyeOutlined
                                  style={{
                                    lineHeight: "30pt",
                                  }}
                                />
                                view
                              </Space>
                            </a>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })
              // )
            }
            {data && data.length === 0 && (
              <div
                style={{
                  width: "100vw",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Empty />
              </div>
            )}
          </Row>
          // </Spin>
        )}
      </Row>
      {/* pagination */}
      {total > 8 && (
        <Row
          align="bottom"
          justify="space-between"
          style={{ margin: "10px 16px 0px 16px" }}
        >
          <h1>
            {t("Total")} {total} {total > 1 ? t("Videos") : t("Video")}
          </h1>
          <Space
            direction="horizontal"
            align="center"
            style={{ justifyContent: "center" }}
            wrap
          >
            <Pagination
              className="video-pagination"
              total={total}
              showSizeChanger={false}
              pageSize={pageSize}
              onChange={(page) => setCurrentPage(page)}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ margin: 0, fontWeight: 500 }}>
                {t("Cards per page")} :
              </p>
              <Select
                onSelect={onSelect}
                bordered={false}
                style={{ width: "80px" }}
                value={pageSize}
                defaultValue={8}
                options={[
                  { value: 8, label: 8 },
                  { value: 16, label: 16 },
                  { value: 24, label: 24 },
                  { value: 32, label: 32 },
                ]}
              ></Select>
            </div>
          </Space>
        </Row>
      )}

      {/* modal setting */}
      <Modal
        title="Setting Video"
        open={open}
        onOk={() => setOpen(false)}
        // onCancel={hideModal}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Row justify={"center"}>
          <Col xs={20}>
            <Form>
              <Form.Item name="name" label="Title">
                <Input placeholder={translate("Title")} />
              </Form.Item>
              <Form.Item name="tag" label="Tag">
                {/* <Select
              onClick={() => setOpen(true)}
              ref={selectRef}
              mode="multiple"
              onSelect={(item) =>
                props.setSelectTag([...props.selectTag, item])
              }
              style={{ width: "100%" }}
              autoFocus={true}
              onDropdownVisibleChange={handleSelectOpen}
              value={props.selectTag}
              open={open}
              placeholder={translate("Tag")}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              // dropdownRender={(menu) => (
              //   <>
              //     {menu}
              //     <Divider style={{ margin: "8px 0" }} />
              //     <Space style={{ padding: "0 8px 4px" }}>
              //       <Input placeholder="Please enter tag" ref={inputRef} />
              //       <ColorPicker
              //         format={formatHex}
              //         value={color}
              //         onChange={onColorChange}
              //         onFormatChange={setFormatHex}
              //         placement="topRight"
              //       />
              //       <Button
              //         type="text"
              //         icon={<PlusOutlined />}
              //         onClick={addItem}
              //       >
              //         Add item
              //       </Button>
              //     </Space>
              //   </>
              // )}
              options={items.map((item, index) => ({
                key: index,
                label: item.name,
                value: item.value,
              }))}
              tagRender={tagRender}
            /> */}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AllVideoPage;
