import { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { addRandomTime } from "../sample/usageData";
import { chartConfig } from "./chartConfig";

const CateChart = ({ name, channelData }: any) => {
  useEffect(() => {
    var chart = am4core.create(name, am4charts.PieChart);
    var series = chart.series.push(new am4charts.PieSeries());
    chart.legend = new am4charts.Legend();
    console.log("channel data",channelData);
    
    series.dataFields.category = "name";

    if(channelData?.length === 0){
      channelData[0] = {name: "No data", value: 1}
      let label = series.createChild(am4core.Label);
      label.text = "No data";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.fontSize = 20;
    }

    chart.data = channelData;
    if (name === "Monthly Usage (Duration)") {
      series.slices.template.tooltipText = "{name} {text} Hrs";
    } else {
      series.slices.template.tooltipText = "{name} {value} Files";
    }

    series.colors.list = chartConfig.colorSetPalette2;
    series.labels.template.text = chartConfig.labelTemplateText;
    series.labels.template.fontSize = chartConfig.labelTextSize;
    chart.legend.labels.template.tooltipText = "{category}: {value}";
    chart.legend.fixedWidthGrid = true;
    chart.legend.maxWidth = chartConfig.legendWidth;
    chart.legend.align = chartConfig.legendAlign;
    chart.legend.position = chartConfig.legendPosition;

    series.dataFields.value = "value";
    series.labels.template.wrap = true;
    series.labels.template.truncate = true;
    chart.legend.valueLabels.template.text = "";

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = chartConfig.markerSize;
    markerTemplate.height = chartConfig.markerSize;

    chart.responsive.enabled = true;

    const updateChart = () => {
      const width = window.innerWidth;

      if (width < 1024) {
        chart.radius = am4core.percent(60);
      } else if (width >= 1024 && width < 1200) {
        chart.radius = am4core.percent(70);
      } else if (width >= 1200) {
        chart.radius = am4core.percent(60);
      } else if (width >= 1600) {
        chart.radius = am4core.percent(70);
      } else {
        chart.radius = am4core.percent(80);
      }
    };

    updateChart();

    window.addEventListener("resize", updateChart);

    return () => {
      window.removeEventListener("resize", updateChart);
      chart.dispose();
    };
  }, [channelData, name]);

  return <div id={name} className="w-auto h-full" />;
};

export default CateChart;
