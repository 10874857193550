import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { useEffect, useState } from "react"
import { Card, Typography } from 'antd'
import { translate } from "../../../utils/libs/translate";
import { org_getAll } from "../../../services/organization";
import { useAuth } from "../../../utils/Auth/AuthWrapper";
import CateChart from "./CateChart";

function CateCard({ name, view,data }) {
  const [channelData, setChannelData] = useState(null)
  const { user } = useAuth();
  const { role } = user

  return (
    <Card title={<Typography.Title level={3} className="card-head-text">
      {translate(name)}
    </Typography.Title>}
      className="cate-card h-full"
    >
      <CateChart name={name} channelData={data} />

    </Card>
  )
}

export default CateCard