import { getToken } from "../utils/prefix";

export async function selectedModel(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/model/selectmodel`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function selectedAllModel(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/model/search`, {
    body: JSON.stringify(data),
    method: "GET",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function createModel(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/model/create`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function deleteModel(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/model/delete`, {
    body: JSON.stringify(data),
    method: "DELETE",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function editModel(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/model/update`, {
    body: JSON.stringify(data),
    method: "PUT",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
