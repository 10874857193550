import useNotification from "../../../components/Notification"
import "../index.less"
import ManageTable from '../ManageTable'
import { useEffect, useState } from 'react'
import { org_getAll, org_getOptions } from '../../../services/organization'
import { useRecoilState } from 'recoil'
import { localeState } from '../../../utils/atoms'

function Organization() {
    const [locale, setLocale] = useRecoilState(localeState)
    const{openNotification,contextHolder} = useNotification();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [modalState, setModalState] = useState("Add")
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [search_query, setSearchQuery] = useState()
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        getData()
    }, [currentPage, search_query, pageSize])

    const getData = async () => {
        const data = {
            page: currentPage,
            size: pageSize,
            order_by: [],
            query: search_query,
            exportData: false,
        };
        try {
            setLoading(true)
            let res = await org_getAll(data)
            if (res.status === 200 && res?.result) {
                if (res?.result.length === 0) {
                    setCurrentPage(1)
                }
                setLoading(false)
                setTotal(res.total)
                setData(res.result)
            } else {
                openNotification("error", 'Cannot get data ! please try again')
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
            openNotification("error", 'Cannot get data ! please try again')
        }
    }
    return (
        <>
            {contextHolder}
            <ManageTable
                side="Organization"
                srcData={data}
                setSrcData={setData}
                modalState={modalState}
                setModalState={setModalState}
                getData={getData}
                loading={loading}
                setLoading={setLoading}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={total}
                setSearchQuery={setSearchQuery}
            />
        </>
    )
}

export default Organization