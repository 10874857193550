import StatusCard from '../components/StatusCard'
import { Col, Row } from 'antd'

function UserPage(data) {
    const member = data.data ? data.data.member : null
    const team = data.data ? data.data.team : null
  return (
      <Row gutter={[20,10]} style={{ flex: 1, marginBottom: '10px' }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <StatusCard sidePrefix="Your" view={'User'} data={member}/>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <StatusCard sidePrefix="Team" view={'User'} data={team}/>
          </Col>
      </Row>
  )
}

export default UserPage