import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

function Unauthorized() {
  let navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        maxHeight: "100%",
      }}
    >
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button
            type="primary"
            style={{ width: "100px" }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        }
      />
    </div>
  );
}

export default Unauthorized