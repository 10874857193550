import {
  Button,
  Modal,
  Spin
} from "antd";
import UserForm from "./user/UserForm";
import OrganizationForm from "./organization/OrganizationForm";
import { translate } from "../../utils/libs/translate";

function FormManage({UserData,form,modalState,side,isModalOpen,handleCancel,onFinish,submitting}:any) {

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      title={`${translate(modalState + " " + side)}`}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          {translate("Cancel")}
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={submitting}
          onClick={handleSubmit}
        >
          {modalState === "Add" ? translate("Add") : translate("Save")}
        </Button>,
      ]}
    >
      <Spin spinning={submitting}>
        {side === "User" ? (
          <UserForm
            USER={UserData}
            form={form}
            onFinish={onFinish}
            modalState={modalState}
          />
        ) : (
          <OrganizationForm form={form} onFinish={onFinish} />
        )}
      </Spin>
    </Modal>
  );
}

export default FormManage;
