import { Input,Form } from 'antd'
import { translate } from '../../../utils/libs/translate'

const OrganizationForm = ({ form, onFinish }) => {
  
  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        style={{ display: 'none' }}
        name="org_id"
        label="id">
        <Input />
      </Form.Item>
      <Form.Item
        name="org_name"
        label={translate("Name")}
        rules={[{ required: true, message: translate('Please enter the organization name') }]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default OrganizationForm