
import {
  ConfigProvider,
  FloatButton,
  Layout,
  Grid,

} from "antd";
import { MenuOutlined, } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { localeState } from '../utils/atoms/index'
import ContentLayout from "./content";
import SiderLayout from "./sidebar";
import HeaderLayout from "./header";
import { useRecoilState } from "recoil";
import { getMenuSider } from "../config/menu/configMenu";
import "./index.less"

const DefaultLayout = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [locale, setLocale] = useRecoilState(localeState)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  Object.keys(screens).filter((item) => {
    if (screens[item] === false) {
      delete screens[item];
    }
  });
  const menuItem = getMenuSider();

  return (
    <ConfigProvider locale={locale}>
      <Layout style={{ minHeight: "100vh" }}>
        <HeaderLayout collapsed={collapsed} />
        <Layout hasSider>
          {
            Object.keys(screens)[Object.keys(screens).length - 1] === "xs" ?
              <FloatButton.Group trigger="click"
                style={{ right: 24 }}
                type="primary"
                icon={<MenuOutlined />}>
                {menuItem.map((item, index) => {
                  if (item?.children) {
                    const subFloatButton = item.children.map((subItem, subIndex) => {
                      if (subItem !== null) {
                        return (
                          <FloatButton className="slide-in-bottom" tooltip={subItem.label} key={subItem.key} icon={subItem.icon} trigger="click" onClick={() => navigate(subItem.path)} />
                        )
                      }
                    })
                    return (
                      <div>
                        {
                          menuOpen &&
                          subFloatButton
                        }
                        <FloatButton type="primary" icon={item.icon} onClick={() => setMenuOpen(prev => !prev)} />
                      </div>
                    );
                  } else {
                    return (
                      <FloatButton tooltip={item.label} icon={item.icon} key={index} onClick={() => navigate(item.path)} />
                    )
                  }

                })}
              </FloatButton.Group>
              :
              <SiderLayout collapsed={collapsed} setCollapsed={setCollapsed} />
          }
          <ContentLayout collapsed={collapsed} />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;
