const config = {
    api_backend: "http://localhost:14001/api",
    // api_backend: "https://ai9.ddns.net/memo/api",
    video_path:"http://localhost:14001/api/task/download?task_id=",
    vdo_img_path:"http://localhost:14001/api/",
  };
  export default config;

const roleConfig = {
  Admin:"Admin",
  User:"User",
  SuperAdmin:"SuperAdmin"
}

export {roleConfig}
  