import React from "react";
import { Row, Col, Button, Image } from "antd";
import { useNavigate } from "react-router-dom";

type Props = {};

const Page404 = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Row style={{ height: "100%" }} justify={"center"} align={"middle"}>
      <Col>
        <Row justify={"center"}>
          <Image src="/image/404-error.png" preview={false} height={"80%"} />
        </Row>
        <Row justify={"center"}>
          <Button type="primary" onClick={() => navigate("/")}>
            Go Home
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default Page404;
