import useNotification from "../../../components/Notification"
import "../index.less"
import ManageTable from '../ManageTable'
import { useEffect, useState } from 'react'
import { add_member, org_getMember } from '../../../services/organization'
import { useRecoilState } from 'recoil'
import { localeState } from '../../../utils/atoms'
import { useAuth } from "../../../utils/Auth/AuthWrapper"
import { roleConfig } from "../../../config/config"
import { user_register } from "../../../services/user"

function User() {
    const [locale, setLocale] = useRecoilState(localeState)
    return (
        <>
            <ManageTable side="User"/>
        </>
    )
}

export default User