import { getToken } from "../utils/prefix";

export async function keywordAPI_getAll(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/keyword/getall`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_getWord(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/getallword`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_addKeywordList(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/createlist`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_addWord(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/createword`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_getModelList() {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/modellist`,
    {
      method: "GET",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_deleteKeywordList(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/deletelist`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_deleteKeywordId(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/deletewordid`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_editKeywordId(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/editwordid`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_getResultModel(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/getresult`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_checkWordDuplicate(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/keyword/checkduplicate`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_getSeeds() {
  const res = await fetch(
    process.env.REACT_APP_TEXT_API_URL + `/service/get-seeds/`,
    {
      method: "GET",
      headers: {
        "x-access-token": process.env.REACT_APP_TEXT_API_URL,
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function keywordAPI_updateSeeds(data) {
  const res = await fetch(
    process.env.REACT_APP_TEXT_API_URL + `/service/update-seeds/`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": process.env.REACT_APP_TEXT_API_URL,
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}
