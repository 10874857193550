import { EditOutlined } from '@ant-design/icons';
import { Avatar, Popconfirm, Space, Switch, Tooltip } from 'antd'
import moment from 'moment'
import { translate } from '../../../utils/libs/translate';
// import 'moment/locale/th'
import { localeState } from '../../../utils/atoms';
import { useRecoilState } from 'recoil';
moment.locale("en");
const OrgColumns = ({ handleSwitchChange, editOrganize,cancel }) =>{
  const [locale, setLocale] = useRecoilState(localeState)
  moment.locale(locale.locale)

  const orgColumn = [
    {
      title: translate("Active"),
      dataIndex: "status",
      key: "status",
      className: "table-td",
      fixed:'left',
      width: 80,
      render: (status, record) => (
        <Popconfirm
          title={status === 0 ? translate("Activate this organization?") : translate("Deactivate this organization?")}
          onConfirm={() => handleSwitchChange(!status, record)}
          onCancel={cancel}
          okText={translate('Yes')}
          okButtonProps={{ danger: true }}
          cancelText={translate('No')}
        >
          <Switch className='table-switch' checked={status} />
        </Popconfirm>
      ),

    },
    {
      title: translate("Organization Name"),
      dataIndex: "name",
      key: "name",
      className: "table-td",
      fixed:'left',
      render: (name) => (
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
          <Avatar style={{ marginRight: 8 }}>{name.charAt(0)}</Avatar>
          <div>{name}</div>
        </div>
      ),
    },
    {
      title: translate("Created date"),
      dataIndex: "created_timestamp",
      key: "created_timestamp",
      className: "table-td center",
      render: (date) => (
        <div style={{ textAlign: 'center' }}>
          {moment(date).fromNow()}
        </div>
      ),
    },
    {
      title: translate("Actions"),
      dataIndex: "action",
      className: "table-td center",
      fixed:'right',
      width: 100,
      render: (_, record) => (
        <Space className='icon-group w-full'>
          <Tooltip title="Edit" placement="top">
            <EditOutlined className='action-icon primary' onClick={() => editOrganize(record)} />
          </Tooltip>
        </Space>
      )
    }
  ]
  return orgColumn
}

export default OrgColumns