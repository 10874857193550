import { useEffect, useRef, useState } from "react";
import "./styles/index.less";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  Tag,
  Form,
  Spin,
  Pagination,
  Space,
  Select,
  Grid,
  Tooltip,
} from "antd";
import moment from "moment";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  createScenario,
  deleteScenario,
  getData_Scenario,
} from "../../../services/scenario";
import { useRecoilState } from "recoil";
import { localeState } from "../../../utils/atoms";
import useNotification from "../../../components/Notification";
import { selectedAllModel, selectedModel } from "../../../services/model";
import { useNavigate } from "react-router-dom";
import { translate } from "../../../utils/libs/translate";
import { t } from "i18next";
import { useSocket } from "../../../utils/socketContext/socketProvider";
import { useAuth } from "../../../utils/Auth/AuthWrapper";

const { confirm } = Modal;

type Props = {};

const Scenario = (props: Props) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useNotification();
  const [scenarioData, setScenarioData]: any = useState([]);
  const [topic, setTopic]: any = useState([]);
  const [object, setObject]: any = useState([]);
  const [scene, setScene]: any = useState([]);
  const [socketUpdate, setSocketUpdate] = useState<boolean>(false);
  const { socket } = useSocket();
  const [isModalOpen, setIsModalOpen] = useState<any>(false);
  const [resultScenario, setResultScenario] = useState<any>({
    text: [],
    object: [],
    scene: [],
  });
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<String>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { useBreakpoint } = Grid;
  const screens: any = useBreakpoint();
  Object.keys(screens).filter((item) => {
    if (screens[item] === false) {
      delete screens[item];
    }
  });
  const columns: any = [
    {
      title: t("Scenario"),
      dataIndex: "title",
      key: "title",
      className: "scenario-name",
      sorter: (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix(),
    },
    {
      title: t("Text"),
      key: "text",
      dataIndex: "text",
      align: "center",
      // render: (_: any, record: any) => record.text,
    },
    {
      title: t("Object"),
      key: "object",
      dataIndex: "object",
      align: "center",
      // render: (_: any, record: any) => record.object,
    },
    {
      title: t("Scene"),
      key: "scene",
      dataIndex: "scene",
      align: "center",
      render: (_: any, record: any) => record.scene,
    },
    {
      title: t("Actions"),
      dataIndex: "",
      align: "center",
      key: "tags",
      render: (_: any, record: any) => (
        <Row gutter={16} justify={"center"}>
          <Col
            style={{ color: "#989898", cursor: "pointer" }}
            onClick={() =>
              navigate(`/setting/settingSce/${record.scenario_id}`)
            }
          >
             <Tooltip title="Edit" placement="top">
              <EditOutlined className="icon primary" />
             </Tooltip>
          </Col>
          <Col
            style={{ color: "#989898", cursor: "pointer" }}
            onClick={() => {
              showDeleteConfirm(record.scenario_id);
            }}
          >
             <Tooltip title="Delete" placement="top">
              <DeleteOutlined className="icon danger" />
             </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [currentPage, searchValue, pageSize]);

  useEffect(() => {
    socket.emit("join_room", user?.org_id);
  }, []);

  useEffect(() => {
    socket.on("scenario_update", () => {
      setSocketUpdate(true);
    });

    return () => {
      socket.off("scenario_update");
    };
  }, [socket]);

  useEffect(() => {
    if (socketUpdate) {
      getData();
      setSocketUpdate(false);
    }
  }, [socketUpdate]);

  const delayLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 250);
  };

  const getData = async () => {
    try {
      const result = {
        page: currentPage,
        size: pageSize,
        order_by: [],
        query: searchValue,
        exportData: false,
      };
      setLoading(true);
      const res = await getData_Scenario(result);
      delayLoading();
      if (res.success === true) {
        setTotal(res.meta.total);
        setScenarioData(res.data);
        if (res.data == null || res.data.length === 0) {
          setCurrentPage(1);
        }
      } else {
        openNotification("error", "Cannot get scenario data !", "");
      }
    } catch (e) {
      delayLoading();
      openNotification("error", "Cannot get scenario data !", "");
      console.error(e);
    }
  };

  const getDateModel = async () => {
    try {
      let text: any = [];
      let object: any = [];
      let scene: any = [];
      const res = await selectedAllModel();

      if (res.status === 200) {
        console.log("res", res.data);
        res.data.map((d: any) => {
          if (d.model == "text") {
            text.push(d);
          } else if (d.model == "object") {
            object.push(d);
          } else if (d.model == "scene") {
            scene.push(d);
          }
        });
        sortData(text);
        sortData(object);
        sortData(scene);

        setTopic(text);
        setObject(object);
        setScene(scene);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const sortData = async (data: any) => {
    return data.sort(
      (a: any, b: any) =>
        moment(a.created_timestamp).unix() - moment(b.created_timestamp).unix()
    );
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: t("Are you sure delete this scenario?"),
      icon: <ExclamationCircleFilled style={{ color: "#FF3B30" }} />,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: async () => {
        console.log("delete row:", id);

        try {
          const res = await deleteScenario({ scenario_id: id });
          getData();
        } catch (e) {
          console.error(e);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCheckModel = async (
    values: any,
    topics: string,
    checked: any
  ) => {
    const result = {
      model_id: values.model_id,
      text: values.text,
      model_name: values.model_name,
      model_type: values.model_type,
    };

    if (topics === "text") {
      checked === false
        ? setResultScenario({
            ...resultScenario,
            text: resultScenario.text.filter(
              (d: any) => d.model_id !== values.model_id
            ),
          })
        : setResultScenario({
            ...resultScenario,
            text: [...resultScenario.text, result],
          });
    } else if (topics === "object") {
      checked === false
        ? setResultScenario({
            ...resultScenario,
            object: resultScenario.object.filter(
              (d: any) => d.model_id !== values.model_id
            ),
          })
        : setResultScenario({
            ...resultScenario,
            object: [...resultScenario.object, result],
          });
    } else {
      checked === false
        ? setResultScenario({
            ...resultScenario,
            scene: resultScenario.scene.filter(
              (d: any) => d.model_id !== values.model_id
            ),
          })
        : setResultScenario({
            ...resultScenario,
            scene: [...resultScenario.scene, result],
          });
    }
  };

  const handleCreateScenario = async (value: any) => {
    if (
      resultScenario.text.length === 0 &&
      resultScenario.scene.length === 0 &&
      resultScenario.object.length === 0
    ) {
      form.setFields([
        {
          name: "name",
          errors: [t("Please select at least 1 option")],
        },
      ]);
    } else {
      setLoading(true);
      try {
        const res = await createScenario({
          title: value.name,
          // data: resultScenario,
          data: [
            ...resultScenario.text.map((d: any) => {
              return {
                model_id: d.model_id,
                model_type: d.model_type,
              };
            }),
            ...resultScenario.object.map((d: any) => {
              return {
                model_id: d.model_id,
                model_type: d.model_type,
              };
            }),
            ...resultScenario.scene.map((d: any) => {
              return {
                model_id: d.model_id,
                model_type: d.model_type,
              };
            }),
          ],
        });

        if (res.message == "success") {
          await new Promise((resolve) => setTimeout(resolve, 500));
          setLoading(false);
          setTopic([]);
          setObject([]);
          setScene([]);
          setResultScenario({
            text: [],
            object: [],
            scene: [],
          });
          form.resetFields();
          socket.emit("update_scenario", { room: user?.org_id });
          //แก้ขัด
          getData();
          //
          setIsModalOpen(false);
        } else {
          if (res.message == "ข้อมูลซ้ำ") {
            form.setFields([
              {
                name: "name",
                errors: ["ชื่อข้อมูลซ้ำ"],
              },
            ]);
          }
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const onSearch = async (value: string) => {
    setSearchValue(value);
    setCurrentPage(1);
  };

  return (
    <div className="content-container">
      {contextHolder}
      <Row style={{ justifyContent: "space-between" }} wrap>
        <Col>
          <h1 className="header-topic">{t("Scenario")}</h1>
        </Col>
        <Col
          className="input-group"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Input.Search
            className="input-search"
            placeholder={translate("Search")}
            onSearch={onSearch}
            allowClear
          />
          <Button
            type="primary"
            style={{ borderRadius: "5px", width: "fit-content" }}
            onClick={() => {
              getDateModel();
              setIsModalOpen(true);
              // showModal();
              // getTextModel();
            }}
          >
            <PlusOutlined />{" "}
            {Object.keys(screens)[Object.keys(screens).length - 1] === "xs" ||
            Object.keys(screens)[Object.keys(screens).length - 1] === "sm"
              ? t("Add")
              : t("Add Scenario")}
          </Button>
        </Col>
      </Row>
      <Row style={{ justifyContent: "flex-end" }} gutter={5}></Row>
      <div className="table-scenario">
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <Table
              rowClassName="ScenarioTable"
              rowKey="scenario_id"
              columns={columns}
              dataSource={scenarioData}
              loading={loading}
              pagination={false}
              scroll={{
                x: 1000,
              }}
            />
            <Row
              align="bottom"
              justify="space-between"
              style={{ margin: "10px 16px 0px 16px" }}
            >
              <h1>
                {t("Total")} {total}{" "}
                {total > 1 ? t("Scenarios") : t("Scenario")}
              </h1>
              <Space
                direction="horizontal"
                align="center"
                style={{ justifyContent: "center" }}
                wrap
              >
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={total}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: 0, fontWeight: 500 }}>
                    {t("Rows per page ")} :
                  </p>
                  <Select
                    onSelect={(value: number) => setPageSize(value)}
                    bordered={false}
                    style={{ width: "80px" }}
                    value={pageSize}
                    defaultValue={10}
                    options={[
                      { value: 10, label: 10 },
                      { value: 20, label: 20 },
                      { value: 30, label: 30 },
                      { value: 40, label: 40 },
                    ]}
                  ></Select>
                </div>
              </Space>
            </Row>
          </>
        )}
      </div>

      <div style={{ marginTop: "2%", float: "right" }}>
        <Modal
          title={t("Scenario")}
          open={isModalOpen}
          // onOk={handleOk}
          onOk={() => form.submit()}
          onCancel={() => setIsModalOpen(false)}
          footer={[
            <Button key="back" onClick={() => setIsModalOpen(false)}>
              {t("Cancel")}
            </Button>,
            <Button
              type="primary"
              loading={loading}
              onClick={() => form.submit()}
            >
              {t("Add")}
            </Button>,
          ]}
          width={830}
        >
          <div>
            <Form form={form} onFinish={handleCreateScenario} layout="vertical">
              <div style={{ marginBottom: "3%" }}>
                {/* <p>Scenario</p> */}
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t("Please scenario input your name!"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("Scenario")}
                    // value={valueNewScenario}
                    // onChange={(e) => {
                    //   setValueNewScenario(e.target.value);
                    // }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex" }}>
                <Row gutter={[16, 16]} style={{ justifyContent: "center" }}>
                  <Col>
                    <Card style={{ width: 250, height: "300px"}}>
                      <p style={{ marginTop: "-3%" }}>{t("Text")}</p>
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={topic}
                        className="card-scroll"
                        renderItem={(item: any, i) => (
                          <List.Item>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                style={{
                                  color: "#E04D8B",
                                  padding: "0px",
                                  marginRight: "10%",
                                }}
                                // size="small"
                                onClick={(e: any) => {
                                  handleCheckModel(
                                    item,
                                    "text",
                                    e.target.checked
                                  );
                                }}
                              />
                              <Tag color="magenta" className="tagScenario">
                                {item.text}
                              </Tag>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ width: 250, height: "300px" }}>
                      <p style={{ marginTop: "-3%" }}>{t("Object")}</p>
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={object}
                        className="card-scroll"
                        renderItem={(item: any, i) => (
                          <List.Item>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                style={{
                                  color: "#218AE7",
                                  padding: "0px",
                                  marginRight: "10%",
                                }}
                                // size="small"
                                onClick={(e: any) => {
                                  handleCheckModel(
                                    item,
                                    "object",
                                    e.target.checked
                                  );
                                }}
                              />
                              <Tag color="blue" className="tagScenario">
                                {item.text}
                              </Tag>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{ width: 250, height: "300px" }}>
                      <p style={{ marginTop: "-3%" }}>{t("Scene")}</p>
                      <List
                        grid={{ gutter: 16, column: 1 }}
                        dataSource={scene}
                        className="card-scroll"
                        renderItem={(item: any, i) => (
                          <List.Item>
                            <div style={{ display: "flex" }}>
                              <Checkbox
                                style={{
                                  color: "#42a219",
                                  padding: "0px",
                                  marginRight: "10%",
                                }}
                                // size="small"
                                onClick={(e: any) => {
                                  handleCheckModel(
                                    item,
                                    "scene",
                                    e.target.checked
                                  );
                                }}
                              />
                              <Tag color="green" className="tagScenario">
                                {item.text}
                              </Tag>
                            </div>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Scenario;
