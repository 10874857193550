//import element
import Dashboard from "../views/dashboard";
import ModelPage from "../views/settings/Model"
import Scenario from "../views/settings/Scenario";
import EditTable from "../views/settings/EditTable";
import AllVideoPage2 from "../views/videos2/AllVideo";
import AllVideoPageUser from "../views/videosUser/AllVideo";
import VideoPage2 from "../views/videos2/VideoDetail";

// import TestMyFunc from "../views/testMyFunc/player";
import { AreaChartOutlined, DashboardOutlined, FileProtectOutlined, FileSearchOutlined, HistoryOutlined, PlayCircleOutlined, SearchOutlined, SettingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";

//icon

import User from "../views/manageTable/user";
import Organization from "../views/manageTable/organization";

//route
import VideoTable from "../views/videos/videoTable/index"
import VideoDetail from "../views/videos/videoDetail/index"
import NotificationPage from "../views/notification"
import Unauthorized from "../components/Unauthorized";
import Page404 from "../views/404";
import HistoryPage from "../views/historyPage";
import BackupPage from "../views/backupPage";
import KeywordsPage from "../views/Keywords";
import KeywordList from "../views/Keywords/list_keyword";
import SearchPage from "../views/SearchPage";

export const route = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <AreaChartOutlined />,
    access: ['User', 'Admin', "SuperAdmin"],
    showMenu: true,
    component: <Dashboard />,
  },
  {
    path: '/user',
    name: 'User',
    icon: <UserOutlined />,
    access: ["Admin", "SuperAdmin"],
    showMenu: true,
    component: <User />,
  },
  {
    path: '/organization',
    name: 'Organization',
    icon: <TeamOutlined />,
    access: ["SuperAdmin"],
    showMenu: true,
    component: <Organization />,
  },
  {
    name: 'Setting',
    icon: <SettingOutlined />,
    access: ["SuperAdmin"],
    path: '/setting',
    routes: [
      {
        path: '/settingModel',
        name: 'Model',
        icon: <UserOutlined />,
        access: ["SuperAdmin"],
        showMenu: true,
        component: <ModelPage />,
      },
      {
        path: '/settingSce',
        name: 'Scenario',
        icon: <UserOutlined />,
        access: ["SuperAdmin"],
        showMenu: true,
        component: <Scenario />,
      },
      {
        path: '/settingSce/:id',
        name: 'editTable',
        icon: <UserOutlined />,
        access: ["SuperAdmin"],
        showMenu: false,
        component: <EditTable />,
      },
      {
        path: '/keywords',
        name: 'Keywords',
        icon: <UserOutlined />,
        access: ["SuperAdmin"],
        showMenu: true,
        component: <KeywordsPage />,
      },
      {
        path: '/keywords/:id',
        name: 'editKeyword',
        icon: <UserOutlined />,
        access: ["SuperAdmin"],
        showMenu: false,
        component: <KeywordList />,
      },
    ],
  },
  {
    path: '/history',
    name: 'History',
    icon: <FileSearchOutlined />,
    access: ["SuperAdmin"],
    showMenu: true,
    component: <HistoryPage />,
  },
  {
    path: '/backup',
    name: 'Backup',
    icon: <FileProtectOutlined />,
    access: ["SuperAdmin"],
    showMenu: true,
    component: <BackupPage />,
  },
  {
    path: '/video',
    name: 'Video',
    icon: <PlayCircleOutlined />,
    access: ['User', 'Admin'],
    showMenu: true,
    // component: <VideoPage/>,
    routes: [
      {
        path: '/all',
        name: 'All',
        icon: <PlayCircleOutlined />,
        access: ["Admin", "User"],
        showMenu: true,
        component: <VideoTable />,
      },
      {
        path: '/myvideo',
        name: 'My Video',
        icon: <PlayCircleOutlined />,
        access: ['User', 'Admin'],
        showMenu: true,
        component: <VideoTable />,
      },
      {
        path: '/:id',
        name: 'EditVideo',
        icon: <PlayCircleOutlined />,
        access: ["User", "Admin"],
        showMenu: false,
        // component: <VideoPage2 />,
        component: <VideoDetail />,
      },
    ],
    },
    {
      path: '/search',
      name: 'Advanced Search',
      icon: <SearchOutlined />,
      access: ['User', 'Admin'],
      showMenu: true,
      component: <SearchPage />,
    },
    {
      path: '/404',
      name: 'page404',
      icon: <TeamOutlined />,
      access: ["SuperAdmin","User", "Admin"],
      showMenu: false,
      component: <Page404/>,
    },
    {
      path: '/notification',
      name: 'notification',
      icon: <TeamOutlined />,
      access: ["SuperAdmin","User", "Admin"],
      showMenu: false,
      component: <NotificationPage/>,
    },
    {
      path: '/500',
      name: 'page404',
      icon: <TeamOutlined />,
      access: ["SuperAdmin","User", "Admin"],
      showMenu: false,
      component: 500,
    },
    {
      path: '/unauthorized',
      name: 'page404',
      icon: <TeamOutlined />,
      access: ["SuperAdmin","User", "Admin"],
      showMenu: false,
      component: <Unauthorized />,
    },
]