import { DownOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Empty, Image, MenuProps, Row, Space, Tag } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../style/index.less";

// type Props = {
//   dataModel: any;
//   dataScenario: any;
//   reloadObject: boolean;
//   selectModelObj: any;
//   setSelectModelObj: any;
// };

const Object = ({
  dataModel,
  dataScenario,
  reloadObject,
  selectModelObj,
  setSelectModelObj,
}) => {
  const [dataScenario2, setDataScenario2] = useState([]);
  const [mockClick, setMockClick] = useState(true);

  const getData = (keyword) => {
    // const keyword = "cigarette";
    // console.log("keyword", keyword);

    // const res = dataScenario[0].object_task.filter((item) => {
    //   const filterObject = item.annotation.filter((items) => {
    //     return items.object == keyword;
    //   });
    //   console.log("res", dataScenario[0].object_task);
    //   if (filterObject.length != 0) {
    //     return item;
    //   }
    // });
    // setDataScenario2(keyword == undefined ? dataScenario[0].object_task : res);
    ///
   if(keyword.length>=2){
    // alert("22")
    console.log("keyword",keyword)
    const res = dataScenario[0].object_task.filter((item) => {
      const filterObject = item.annotation.filter((items) => {
        return items.object == keyword[keyword.length-1];
      });
      console.log("res", dataScenario[0].object_task);
      if (filterObject.length != 0) {
        return item;
      }
    });
    setDataScenario2([...dataScenario2,...res]);
   }else if (keyword.length==1){

        const res = dataScenario[0].object_task.filter((item) => {
      const filterObject = item.annotation.filter((items) => {
        return items.object == keyword;
      });
      console.log("res", dataScenario[0].object_task);
      if (filterObject.length != 0) {
        return item;
      }
    });
    setDataScenario2(keyword == undefined ? dataScenario[0].object_task : res);
   }else{
    setDataScenario2(dataScenario[0].object_task);
   }


 
// }
  };

  //////
  const dataModelObject = dataModel.filter(
    (i) => i.model_type == "b875130b-0492-11ee-a8f8-0242ac1b0005"
  );

  console.log("dataScenario", dataScenario[0].object_task);
  let alldata = [];
  dataScenario[0].object_task.map((i) => {
    i.annotation.map((d) => {
      alldata.push(d);
    });
  });
  console.log("alldata", alldata);
  const uniqValue = (data,key) => {

return [
  ...new Map(
    data.map(x=> [key(x),x])
  ).values()
]
  }
  // console.log("uniqValue",uniqValue(alldata, item => item.object))
  const objTag = uniqValue(alldata, item => item.object)

  const tagObject = alldata.reduce((group, product) => {
    const { object } = product;
    console.log("object in reduce",object)
    group[object] = group[object] ?? [];
    group[object].push(product);
    return group;
  }, {});
  console.log("selectModelObj", selectModelObj);


  useEffect(() => {
    console.log("in modal canvas");
    // getData();
    // setSelectModelObj(objTag.map((item)=>item.object))
    setDataScenario2(dataScenario[0].object_task)
  }, [reloadObject]);

  return (
    <Row style={{ width: "100%" }}>
      <Col xs={24} style={{ width: "100%" }}>
        <Space size={[0, 8]} wrap>
          {objTag.map(d=>{
            return (
              <Tag
                key={d.object}
                color={selectModelObj.filter((item)=>item == d.object).length == 1  ?  "#c41d52":"pink" }
                // color={selectModelObj != d.object ? "pink" : "#c41d52"}
                style={{ cursor: "pointer" }}
                // onClick={() => setMockClick(!mockClick)}
                onClick={() => {
                  // getData(d.object);
                  getData(selectModelObj.filter((item)=>item==d.object).length == 0 ?
                  [...selectModelObj,d.object]:selectModelObj.filter((item)=>item!=d.object)
                  );
                  selectModelObj.filter((item)=>item==d.object).length == 0 ?
                  setSelectModelObj([...selectModelObj,d.object]):
                  setSelectModelObj(selectModelObj.filter((item)=>item!=d.object))
                }}
                // style={
                //   mockClick
                //     ? {
                //         background: "#0958d9",
                //         color: "white",
                //         cursor: "pointer",
                //       }
                //     : {
                //         background: "#e6f4ff",
                //         color: "#0958d9",
                //         cursor: "pointer",
                //       }
                // }
              >
                {d.object}{" "}(
                {tagObject[d.object] == undefined
                  ? 0
                  : tagObject[d.object].length})
              </Tag>
            );
          })}
          {/* {dataModelObject.map((d: any) => {
            return (
              <Tag
                key={d.model_id}
                // color={d.color}
                color={selectModelObj != d.model_name ? d.color : "#c41d52"}
                style={{ cursor: "pointer" }}
                // onClick={() => setMockClick(!mockClick)}
                onClick={() => {
                  getData(d.model_name);
                  setSelectModelObj(d.model_name);
                }}
                // style={
                //   mockClick
                //     ? {
                //         background: "#0958d9",
                //         color: "white",
                //         cursor: "pointer",
                //       }
                //     : {
                //         background: "#e6f4ff",
                //         color: "#0958d9",
                //         cursor: "pointer",
                //       }
                // }
              >
                {d.text}{" "}
                {tagObject[d.model_name] == undefined
                  ? 0
                  : tagObject[d.model_name].length}
              </Tag>
            );
          })} */}
        </Space>
      </Col>
      <Col
        xs={24}
        style={{
          background: "#E2E2E8",
          // height: "320px",
          height: "370px",
          borderRadius: "4px",
          marginTop: "0.5rem",
          overflowX: "hidden",
          overflowY: "scroll",
          // padding: "30px 30px 20px 30px",
        }}
      >
        <Row gutter={[16, 16]}>
          {dataScenario2&&dataScenario2.map((d, ind) => {
            console.log("ddd image", d.screenshot);
            return (
              <Col
                key={ind}
                flex="0 1 33%"
                style={{
                  // padding: "4px",
                  width: "100%",
                }}
              >
                <Row>
                  <Image
                    // src={process.env.REACT_APP_BASE_PATH + d.screenshot}
                    src={d.screenshot}
                    width={"100%"}
                    height={"auto"}
                    preview={true}
                  />
                </Row>
              </Col>
            );
          })}
          {dataScenario2.length == 0 && (
            <Col xs={24} style={{ minWidth: "100%" }}>
              <Empty />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Object;
