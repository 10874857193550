import React, { useState } from "react";
import { Button, Col, Radio, Row, Space, Tabs, Tag } from "antd";
import Text from "./component/Text";
import Object from "./component/Object";
import Scene from "./component/Scene";

type Props = {
  dataTask: any;
  radioScenario: number;
  setRadioScenario: (i: any) => any;
  getScenario_model: (i: any) => any;
  dataModel: any;
  radioModel: any;
  dataScenario: any;
  setSeekTime: (res: any) => any;
  setEndTime: (res: any) => any;
  focusRow:any
  setFocusRow:any
};

const DetailDisplay = (props: Props) => {
  const [selectScene, setSelectScene] = useState<any>(null);
  const [reloadObject, setReloadObject] = useState(false);
  const [reloadScene, setReloadScene] = useState(false);
  const [selectModelText, setSelectModelText] = useState("");
  const [selectModelObj, setSelectModelObj] = useState([]);
  const [selectModelScene, setSelectModelScene] = useState("");
  const [selectTags, setSelectTags] = useState("");
  const [modelTag, setModelTag] = useState("");
  let myVideo: any = document.getElementById("video");

  return (
    <Col md={24} lg={12}>
      <Row>
        <Tabs
          onChange={(e) => {
            props.getScenario_model(e);
            setSelectModelText("")
            setSelectModelScene("")
            setSelectModelObj([])
            setSelectTags("")
          }}
          className="tab-scenario"
          type="card"
          items={
            props.dataTask &&
            props.dataTask.scenario_id?.map((d: any) => {
              return {
                label: d.title,
                key: d.scenario_id,
              };
            })
          }
        />
      </Row>
      <Row>
        <Col xs={24} className="video-detail-container">
          {/* scenario button */}
          <Row className="w-100per">
            <Radio.Group
              onChange={(e) => props.setRadioScenario(e.target.value)}
              value={props.radioScenario}
              buttonStyle="solid"
              className="scenario-group"
            >
              <Radio.Button
                style={{ width: "33.33%" }}
                disabled={props.radioModel?.text.length == 0 ? true : false}
                value={1}
              >
                <Row justify="center">Text</Row>
              </Radio.Button>
              <Radio.Button
                style={{ width: "33.33%" }}
                disabled={props.radioModel?.object.length == 0 ? true : false}
                value={2}
              >
                <Row justify="center">Object</Row>
              </Radio.Button>
              <Radio.Button
                style={{ width: "33.33%" }}
                disabled={props.radioModel?.scene.length == 0 ? true : false}
                value={3}
              >
                <Row justify="center">Scene</Row>
              </Radio.Button>
            </Radio.Group>
          </Row>
          {/* detail */}
          <div className="detail-scenario">
            <Row className="w-100per">
              <h2 className="w-100per">
                {props.radioScenario == 1 ? (
                  <Row className="w-100per" justify="space-between">
                    <Col>
                      <h2>Text</h2>
                    </Col>
                    <Col>
                      {/* <Button
                        onClick={() => {
                          setSelectModelText("");
                          setSelectTags("");
                        }}
                      >
                        Show All
                      </Button> */}
                    </Col>
                  </Row>
                ) : props.radioScenario == 2 ? (
                  <Row className="w-100per" justify="space-between">
                    <Col>
                      <h2>Object</h2>
                    </Col>
                    <Col>
                      {/* <Button
                        onClick={() => {
                          // setSelectModelObj("");
                          setModelTag("");
                          setSelectModelObj([]);
                          setReloadObject(!reloadObject);
                        }}
                      >
                        Show All
                      </Button> */}
                    </Col>
                  </Row>
                ) : (
                  <Row className="w-100per" justify="space-between">
                    <Col>
                      <h2>Scene</h2>
                    </Col>
                    <Col>
                      {/* <Button
                        // disabled={selectScene != null ? false : true}
                        onClick={() => {
                          setSelectModelScene("");
                          setSelectScene(null);
                          setReloadScene(!reloadScene);

                          props.setEndTime(null);
                          if (myVideo) {
                            myVideo.currentTime = 0;
                          }
                        }}
                      >
                        Show All
                      </Button> */}
                    </Col>
                  </Row>
                )}
              </h2>
            </Row>
            <Row>
              {props.radioScenario == 1 ? (
                <Text
                  dataModel={props.dataModel}
                  dataScenario={props.dataScenario}
                  selectModelText={selectModelText}
                  setSelectModelText={setSelectModelText}
                  selectTags={selectTags}
                  setSelectTags={setSelectTags}
                  focusRow={props.focusRow}
                  setFocusRow={props.setFocusRow}
                />
              ) : props.radioScenario == 2 ? (
                <Object
                  dataModel={props.dataModel}
                  dataScenario={props.dataScenario}
                  reloadObject={reloadObject}
                  selectModelObj={selectModelObj}
                  setSelectModelObj={setSelectModelObj}
                  modelTag={modelTag}
                  setModelTag={setModelTag}
                  radioModel={props.radioModel}
                  setSeekTime={props.setSeekTime}
                  setEndTime={props.setEndTime}
                />
              ) : (
                <Scene
                  dataModel={props.dataModel}
                  dataScenario={props.dataScenario}
                  setSeekTime={props.setSeekTime}
                  setEndTime={props.setEndTime}
                  selectScene={selectScene}
                  setSelectScene={setSelectScene}
                  reloadScene={reloadScene}
                  selectModelScene={selectModelScene}
                  setSelectModelScene={setSelectModelScene}
                  dataTask={props.dataTask}
                />
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default DetailDisplay;
