export async function getHistory_apiAdmin(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/history/getall`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": process.env.REACT_APP_TOKEN_BACKEND,
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}
