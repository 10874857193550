import { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { chartConfig } from "./chartConfig";

function RiskDetectChart({ chartId, data }: any) {
  
  const createChart = () => {
    const width = window.innerWidth;
    const chart = am4core.create(`${chartId}`, am4charts.PieChart);

    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "category";

    // if (data[0].value === 0 && data[1].value === 0) {
    if (data.length == 0) {

      data.push({category:"No Data",value:1})
      // data[1].value = 0;
      let label = series.createChild(am4core.Label);
      label.text = "No data";
      label.horizontalCenter = "middle";
      label.verticalCenter = "middle";
      label.fontSize = 20;
    }
    chart.data = data;
    series.labels.template.text = ""
    // series.labels.template.text = "{value.percent.formatNumber('#.#')}%";
    // series.labels.template.text;
    series.labels.template.fontSize = chartConfig.labelTextSize;
    series.labels.template.fontWeight = chartConfig.labelTextWeight;
    // series.slices.template.tooltipText = "{category} {value} Files";
    series.slices.template.tooltipText = chartId == "Scenario Detected"?"{category} {value} Spots":"{category} {value} Files";
    series.colors.list =
      chartId === "Scenario Detected"
        ? chartConfig.colorSetPalette
        : chartConfig.colorSet;

    chart.legend = new am4charts.Legend();
    chart.legend.valueLabels.template.text = "";
    chart.legend.labels.template.tooltipText = "{category}: {value}";
    chart.legend.labels.template.fontSize = '1vh';
    chart.legend.align = chartConfig.legendAlign;
    chart.legend.position = chartConfig.legendPosition;
    chart.legend.fixedWidthGrid = true;
    chart.legend.maxWidth = chartConfig.legendWidth;
    chart.legend.markers.template.width = chartConfig.markerSize;
    chart.legend.markers.template.height = chartConfig.markerSize;
    // chart.responsive.enabled = true;

    return chart;
  };

  useEffect(() => {
    const chart = createChart();
    const updateChart = () => {
      const width = window.innerWidth;

      if (width < 1024) {
        chart.radius = am4core.percent(60);
      } else if (width >= 1024 && width < 1200) {
        chart.radius = am4core.percent(70);
      } else if (width >= 1200) {
        chart.radius = am4core.percent(60);
      } else if (width >= 1600) {
        chart.radius = am4core.percent(70);
      } else {
        chart.radius = am4core.percent(80);
      }
    };

    updateChart();

    window.addEventListener("resize", updateChart);

    return () => {
      window.removeEventListener("resize", updateChart);
      chart.dispose();
    };
  }, [data]);

  return <div id={chartId} className="w-auto h-full"></div>;
}

export default RiskDetectChart;
