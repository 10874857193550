import { useAuth } from "../../utils/Auth/AuthWrapper";
import { useEffect, useState } from "react";
import { Form, Input, Button, Spin, message, Row, Col } from "antd";
import LogoLogin from "../../assets/images/login_image.png";
import MemoLogo from "../../assets/images/icon-memo.png";
import { useNavigate } from "react-router-dom";
import "./index.less";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { user_login } from "../../services/user";
import useNotfication from "../../components/Notification";
import { useSocket } from "../../utils/socketContext/socketProvider";
import { io } from "socket.io-client";
import { QuestionCircleOutlined } from "@ant-design/icons";
type Props = {};

const LoginPage = (props: Props) => {
  let navigate = useNavigate();
  const { openNotification, contextHolder } = useNotfication();
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();
  const socket = useSocket();

  const handleLogin = async (value: any) => {
    setSubmitting(true);
    try {
      const data = {
        user_email: value.email,
        password: value.password,
      };
      const res = await user_login(data);

      if (res.message == "success" && res.status == 1) {
        socket.socket.emit("forceDisconnect");
        socket.socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
          path: `${process.env.REACT_APP_SOCKET_PATH}`,
        });
        openNotification("success", "Login Success", "Description");
        auth.login(res.result);
      } else {
        if (res.statusCode == 404) {
          form.setFields([
            {
              name: "password",
              errors: [res.error_message],
            },
          ]);
        } else {
          form.setFields([
            {
              name: "email",
              errors: [res.message],
            },
          ]);
        }
      }
    } catch (e) {
      console.error(e);
      message.error("Email or password is incorrect, please try again !");
    }
    setSubmitting(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    const timer = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };

    timer();
  }, []);

  const downloadFile = () => {
    //
    fetch(`${process.env.REACT_APP_FRONT_URL}memo.pdf`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Manual.pdf";
        alink.click();
      });
    });
  };

  return (
    <>
      <div className="login-page">
        {contextHolder}
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <Row className="login-box">
              <Col xs={0} sm={0} md={0} lg={10} xl={14}>
                <div className="illustration-wrapper-log">
                  <img className="login-image" src={LogoLogin} alt="Login" />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={14} xl={10}>
                <Form
                  layout="vertical"
                  form={form}
                  name="login-form"
                  initialValues={{
                    autoLogin: true,
                    email: "nectec@nectec.or.th",
                    password: "nectec12345678",
                  }}
                  onFinish={async (values) => {
                    handleLogin(values);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={MemoLogo}
                      alt="Login"
                      style={{ width: "auto", height: 60 }}
                    />
                    <p className="form-title">Login</p>
                  </div>
                  <Form.Item
                    label={
                      <>
                        Email{" "}
                        <div style={{ color: "red", marginLeft: "5px" }}>*</div>
                      </>
                    }
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not a valid -mail",
                      },
                      { required: true, message: "Please input your email!" },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    label={
                      <>
                        Password{" "}
                        <div style={{ color: "red", marginLeft: "5px" }}>*</div>
                      </>
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={submitting}
                    >
                      Login
                    </Button>
                  </Form.Item>
                  <Button type="link" onClick={downloadFile}>
                    <QuestionCircleOutlined />
                    ดาวน์โหลดคู่มือการใช้งานระบบ
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default LoginPage;
