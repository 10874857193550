import { createContext, useContext, FC, useState } from "react";
import socketIO, { io, Socket } from "socket.io-client";

let socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
  path: `${process.env.REACT_APP_SOCKET_PATH}`,
});

interface SocketContextInterface {
  socket: Socket;
}

const SocketContext = createContext<SocketContextInterface>({ socket });

interface Props {
  children: React.ReactNode;
}

const SocketProvider: FC<Props> = (props) => {
  return (
    <SocketContext.Provider value={{ socket }}>
      {props.children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);

export default SocketProvider;
