import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Popconfirm, Space, Switch, Tooltip } from "antd";
import moment from "moment";
import "../index.less"
import { translate } from "../../../utils/libs/translate";

moment.updateLocale("th", {
  day: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Today at] LT",
    lastWeek: "[Last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "dddd DD [at] LT",
  },
});

const UserColumns = ({ USER, deleteConfirm, editUser, handleSwitchChange, cancel, sortedInfo }) => {
  const userColumns = [
    {
      title: translate("Active"),
      dataIndex: "status",
      key: "status",
      className: "table-td",
      fixed: 'left',
      width: 80,
      render: (status, record) => (
        <Popconfirm
          title={status === 0 ? translate("Activate this user?") : translate("Deactivate this user?")}
          onConfirm={() => handleSwitchChange(!status, record)}
          onCancel={cancel}
          okText={translate("Yes")}
          okButtonProps={{ danger: true }}
          cancelText={translate("No")}
        >
          <Switch className='table-switch' checked={status} />
        </Popconfirm>
      ),

    },
    {
      title: translate("Name"),
      dataIndex: "user_name",
      key: "user_name",
      className: "table-td",
      // sorter:(a,b)=>{
      //   if(a.user_name < b.user_name){
      //     return -1
      //   }else if(a.user_name > b.user_name){
      //     return 1
      //   }
      // },
      // sortOrder: sortedInfo.columnKey === 'user_name' ? sortedInfo.order : null,
      fixed: 'left',
      width: 300,
      render: (name, record) => (
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
          {record.profile_pic ?
            <Avatar style={{ marginRight: 8 }} src={record.profile_pic}></Avatar>
            : <Avatar style={{ marginRight: 8 }}>{name.charAt(0)}</Avatar>}
          {name}
        </div>
      )
    },
    {
      title: translate("Email"),
      dataIndex: "email",
      key: "email",
      className: "table-td",
    }
    ,
    {
      title: translate("Role"),
      dataIndex: "role_name",
      key: "role_name",
      className: "table-td",
      width: 200,
      render: (role_name) => (
        <>
          {translate(role_name)}
        </>
      )
    },
    {
      title: translate("Actions"),
      dataIndex: "action",
      className: "table-td center",
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <Space className='icon-group w-full'>
          <Tooltip title="Edit" placement="top">
            <EditOutlined className='icon primary' onClick={() => editUser(record)} />
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <DeleteOutlined className='icon danger' onClick={() => deleteConfirm(record)} />
          </Tooltip>
        </Space>
      )
    }
  ]

  //on Super Admin add status,switch,org name,edit action
  if (USER.role === 'SuperAdmin') {
    userColumns.splice(3, 0, {
      title: translate("Organization"),
      dataIndex: "org_name",
      key: "org_name",
      className: "table-td",
    });
  }
  return userColumns
}

export default UserColumns