import { Button, Col, Row, Space, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import TagList, { Container } from '../../../../components/TagList'
import ContainerDimensions from 'react-container-dimensions'
import { DeleteOutlined, EyeOutlined, SettingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import useNotification from "../../../../components/Notification";
import axios from 'axios'

type Props = {
    dataSource:any
    deleteVideo:any
    form:any
    setOpen:any
}

const TableList = (props: Props) => {
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();
    const { openNotification, contextHolder } = useNotification();

    const columns: any = [
        {
          title: "Name",
          dataIndex: "title",
          key: "title",
          className: "scenario-name",
          width:"25%",
         
        },
        {
          title: "Tag",
          key: "tag",
          dataIndex: "tag",
          align: "center",
          width:"15%",
          // render:(_: any, record: any)=>{
          //   if(record.tag == null){
          //     return <Row justify={'center'}>No Tag</Row>
          //   }else{
          //     return record.tag
          //   }
          // }
          render: (_: any, record: any) =>{
            // if( record.tag == null){
            //     return "No Tag"
            // }else{
                return(
                    <Container>
                              <ContainerDimensions>
                                {({ width }) => (
                                  <TagList
                                    parentWidth={width}
                                    tags={record.tag}
                                    setSearchValue={setSearchValue}
                                  />
                                )}
                              </ContainerDimensions>
                            </Container>
                )
            // }
            
          },
        },
        {
          title: "Duration",
          key: "duration",
          dataIndex: "duration",
          align: "center",
          width:"10%",
          render: (_: any, record: any) => {
            return(
                new Date(record.duration * 1000)
                    .toISOString()
                    .slice(11, 19)
            )
          },
        },
        {
          title: "Create By",
          key: "name",
          dataIndex: "name",
          align: "center",
          width:"15%",
          // render: (_: any, record: any) => record.object,
        },
        {
          title: "Create Date",
          key: "created_timestamp",
          dataIndex: "created_timestamp",
          align: "center",
          render: (_: any, record: any) => moment(record.created_timestamp).fromNow(),
        },
        {
          title: "Status",
          key: "avg_status",
          dataIndex: "avg_status",
          align: "center",
          width:"10%",
          render: (_: any, record: any) => {
            if( record.status == process.env.REACT_APP_ERR){
              return <div style={{color:"#fa4851"}}>Fail Upload</div>
            }else if(record.status == process.env.REACT_APP_READY_TO_VIEW ){
              return <div style={{color:"rgb(94, 194, 0,0.8)"}}>Ready To View</div>
            }else{
              return <div style={{color:"#e9a80f"}}>{`Processing ${record.avg_status == null ? 20:record.avg_status}%`}</div>
            }
          }
        },
        {
          title: "Action",
          dataIndex: "",
          align: "center",
          key: "tags",
          width: '10%',
          render:(_:any,record:any) => {
            return(
              <Row justify={'center'} gutter={8}>
                <Col>
                  <Space>
                      <Tooltip title="View" placement="top">
                        <EyeOutlined className="lineHeight primary" 
                          onClick={()=>{
                            if (record.status == process.env.REACT_APP_READY_TO_VIEW) {
                              navigate(`/video/${record.task_id}`);
                            } else {
                              // alert("not ready");
                              openNotification(
                                "error",
                                "Video not ready to view",
                                ""
                              );
                            }
                          }}
                        />
                      </Tooltip>
                                {/* View */}
                    </Space>
                </Col>
                <Col>
                  <Space>
                    <Tooltip title="Setting" placement="top">
                      <SettingOutlined className="" style={{color:"#e9a80f"}}
                        onClick={()=>{
                          props.form.setFieldsValue({
                            task_id: record.task_id,
                            name: record.title,
                            tag: record.tag
                              ? record.tag.map((v:any) => v.name)
                              : [],
                          });
                          props.setOpen(true)
                        }}
                      />
                    </Tooltip>
                      {/* <EyeOutlined className="lineHeight" /> */}
                                {/* View */}
                    </Space>
                </Col>
                <Col>
                  <Space>
                      <Tooltip title="Delete" placement="top">
                        <DeleteOutlined className="danger" onClick={()=> props.deleteVideo(record.task_id, record.title)}/>
                      {/* <EyeOutlined className="lineHeight" /> */}
                                {/* View */}
                      </Tooltip>
                    </Space>
                </Col>
              </Row>
            )
          }
         
        },
      ];
  return (
    <>
      <Table rowKey={"task_id"} style={{width:"100%"}} columns={columns} dataSource={props.dataSource} pagination={false}/>
    </>
  )
}

export default TableList