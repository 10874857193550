import { Layout } from "antd";
import { Outlet } from "react-router-dom";

type Props = {
  collapsed: boolean;
};

const ContentLayout = ({ collapsed }: Props) => {
  const { Content } = Layout;
  return (
    <Content
      style={
        collapsed === true
          ? { marginLeft: "0px" }
          : {
              marginLeft: "0px",
              background: "#f5f5f5",
            }
      }
    >
      <Outlet />
    </Content>
  );
};

export default ContentLayout;
