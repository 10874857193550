import { useEffect, useRef, useState } from 'react'
import { Image as ImageTest } from 'antd'

const Canvas = (props) => {
  const canvasRef = useRef(null)
  const [data, setData] = useState('')
  const [data2, setData2] = useState('')

  const [isPreviewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    getData()
  }, [props.dataScenario2])

  const getData = async () => {
    const image = new Image();
    image.src = props.screenshot
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.lineWidth = 6
    context.fillStyle = 'red'
    image.onload = () => {
      var scale = Math.min(canvas.width / image.width, canvas.height / image.height);
      var x = (canvas.width / 2) - (image.width / 2) * scale;
      var y = (canvas.height / 2) - (image.height / 2) * scale;


      context.drawImage(image, x, y, image.width * scale, image.height * scale);

      props.annotation
        .map((value) => {

          context.strokeStyle = "red"
          context.strokeRect(
            Number(value.bounding_box.x1 * image.width) * scale + x,
            Number(value.bounding_box.y1 * image.height) * scale + y,
            Number((value.bounding_box.x2 - value.bounding_box.x1) * image.width) * scale,
            Number((value.bounding_box.y2 - value.bounding_box.y1) * image.height) * scale,
          );
        })
      setData(canvas.toDataURL())
      sleep(3000)
      props.setIsloading(false)
    };
  } 

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  const createPreview = async (visible, prevVisible, annotation) => {
    const image = new Image();
    image.src = props.screenshot

    const canvas = document.createElement("canvas");
    canvas.height = 720;
    canvas.width = 1280;

    const context = canvas.getContext('2d')


    image.onload = async () => {
      var scale = Math.min(canvas.width / image.width, canvas.height / image.height);
      var x = (canvas.width / 2) - (image.width / 2) * scale;
      var y = (canvas.height / 2) - (image.height / 2) * scale;

      context.drawImage(image, x, y, image.width * scale, image.height * scale);

      await annotation
        .map((value) => {
          context.lineWidth = 6
          context.strokeStyle = "red"
          context.strokeRect(
            Number(value.bounding_box.x1 * image.width) * scale + x,
            Number(value.bounding_box.y1 * image.height) * scale + y,
            Number((value.bounding_box.x2 - value.bounding_box.x1) * image.width) * scale,
            Number((value.bounding_box.y2 - value.bounding_box.y1) * image.height) * scale,
          );
        })
      setData2(canvas.toDataURL())
      setPreviewVisible(visible)
    };
  }

  return (
    <>
      {data != '' &&
        <ImageTest
          style={{ width: "100%" }}
          src={data}
          preview={{
            visible: isPreviewVisible,
            onVisibleChange: (visible, prevVisible) => createPreview(visible, prevVisible, props.annotation),
            src: data2,
            toolbarRender: () => <></>,
          }} 
          />}

      <canvas ref={canvasRef} width={426} height={240}
        style={{ display: "none" }}
      />
    </>
  )
}

export default Canvas