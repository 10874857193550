import {
    AppstoreOutlined,
    MailOutlined,
    DashboardFilled,
    VideoCameraFilled,
    UserOutlined,
    SettingOutlined,
  } from "@ant-design/icons";
import { route } from "../../routes/routes";
import { t } from "i18next";

const user = JSON.parse(localStorage.getItem("stt"));


 export const items = route.map((d)=>{
    if(user){
      const res = d.access.filter((acc)=>{
        if(acc== user.role){
          return d
        }
      })

      if(res.length != 0){
      if(d.routes){
       return {
          label: d.name,
          key: d.path,
          icon: <SettingOutlined />,
          access:d.access,
          children:d.routes.map((child)=>{
            return{
                      label: child.name,
                      key: d.path+child.path,
                      icon: <SettingOutlined />,
                      path:d.path+child.path
                    }
          })
        }
      }else{
        return  {
          label: d.name,
          key: d.path,
          icon: <MailOutlined />,
          path: d.path,
          access: d.access,
        }
      }
    }else{
      return null
    }
    }else{
      return null
    }
})

export const getMenuSider = () => {
  const user = JSON.parse(localStorage.getItem("stt"));

  const res = route.map((d)=>{
    if(user){
      const res = d.access.filter((acc)=>{
        if(acc== user.role){
          return d
        }
      })
      if(res.length != 0){
      if(d.routes){
       return {
          // label: d.name,
          label: t(d.name),
          key: d.path,
          icon: d.icon,
          access:d.access,
          children:d.routes.map((child)=>{
            if(child.showMenu){
              return{
                label: t(child.name),
                key: d.path+child.path,
                icon: d.icon,
                path:d.path+child.path
              }
            }else{
              return null
            }
           
          })
        }
      }else{
        if(d.showMenu){
          return  {
            // label: d.name,
            label: t(d.name),
            key: d.path,
            icon: d.icon,
            path: d.path,
            access: d.access,
          }
        }else{
          return  null
        }
        
      }
    }else{
      return null
    }
    }else{
      return null
    }
  })

  const res2 = res.filter((d)=> d != null)
  return res2
}


  // export const items = [
  //   {
  //     label: "User",
  //     key: "/user",
  //     icon: <MailOutlined />,
  //     path: "/user",
  //     access: "Super Admin , Admin",
  //   },
  //   {
  //     label: "Model",
  //     key: "/model",
  //     icon: <AppstoreOutlined />,
  //     path: "/model",
  //     access: "Admin",
  //     // disabled: true,
  //   },
  //   {
  //     label: "Organizaion",
  //     key: "org",
  //     icon: <AppstoreOutlined />,
  //     path: "/organizaion",
  //     access: "Super Admin",
  //     // disabled: true,
  //   },
  //   {
  //     label: "Task",
  //     key: "/task",
  //     icon: <AppstoreOutlined />,
  //     path: "/task",
  //     access: "Admin , User",
  //     // disabled: true,
  //   },
  //   {
  //     label: "Dictionary",
  //     key: "/dictionary",
  //     icon: <AppstoreOutlined />,
  //     path: "/dictionary",
  //     access: "Admin",
  //     // disabled: true,
  //   },
  //   {
  //     label: 'Navigation Three - Submenu',
  //     key: 'SubMenu',
  //     icon: <SettingOutlined />,
  //     children: [
  //       {
  //         type: 'group',
  //         label: 'Item 1',
       
  //         children: [
  //           {
  //             label: 'Option 1',
  //             key: 'setting:1',
  //             icon: <SettingOutlined />,
  //           },
  //           {
  //             label: 'Option 2',
  //             key: 'setting:2',
  //             icon: <SettingOutlined />,
  //           },
  //         ],
  //       },
  //     ]}
  //   //   {
  //   //     label: "Navigation Three - Submenu",
  //   //     key: "SubMenu",
  //   //     icon: <SettingOutlined />,
  //   //     children: [
  //   //       {
  //   //         type: "group",
  //   //         label: "Item 1",
  //   //         children: [
  //   //           {
  //   //             label: "Option 1",
  //   //             key: "setting:1",
  //   //           },
  //   //           {
  //   //             label: "Option 2",
  //   //             key: "setting:2",
  //   //           },
  //   //         ],
  //   //       },
  //   //       {
  //   //         type: "group",
  //   //         label: "Item 2",
  //   //         children: [
  //   //           {
  //   //             label: "Option 3",
  //   //             key: "setting:3",
  //   //           },
  //   //           {
  //   //             label: "Option 4",
  //   //             key: "setting:4",
  //   //           },
  //   //         ],
  //   //       },
  //   //     ],
  //   //   },
  // ];