import { Input, Select,Form } from 'antd'
import { useEffect, useState } from 'react'
import { org_getAll, org_getOptions } from '../../../services/organization'
import { translate } from '../../../utils/libs/translate'


const UserForm = ({ USER, form, onFinish, modalState })=> {
  const [options, setOptions] = useState(null)

  const getOptions = async () => {
    const data = {
      page: 1,
      size: 100,
      order_by: [],
      query: "",
    };
    try {
      // const res = await org_getAll(data)
      let res = await org_getOptions()
      const optionList = res.result.map((item) => {
        return { label: item.name, value: item.name }
      })
      setOptions(optionList)
      console.log(optionList);
    } catch (error) {
      console.log(error);
      setOptions(null)
    }
  }
  useEffect(() => {
    if (USER.role === "SuperAdmin") {
      getOptions()
    }

  }, [USER.role])
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        style={{ display: 'none' }}
        name="member_id"
        label="ID"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="user_name"
        label={translate("Name")}
        rules={[{ required: true, message: translate('Please enter the name') }]}
      >
        <Input />
      </Form.Item>
      {/* add form */}
      {modalState === 'Add' ?
        <>
          <Form.Item
            name="user_email"
            label={translate("Email")}
            rules={[
              { type: "email", message: translate("The input is not a valid email") },
              { required: true, message: translate('Please enter the email') }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={translate("Password")}
            extra={`${translate('default')} : ${USER.org_name}12345678)`}
            rules={[
              { required: true, message: translate("Please enter the password") }
            ]}
            initialValue={USER.org_name + "12345678"}
          >
            <Input.Password/>
          </Form.Item>
        </>
        :
        //on edit form cannot edit email
        <Form.Item
          name="password"
          label={translate("Password")}
          rules={[
            { required: false, message: translate('Please enter the password') }
          ]}
          initialValue={USER.org_name + "12345678"}
        >
          <Input.Password />
        </Form.Item>
      }

      {
        //if user is Super Admin allow to add,edit org and role
        USER.role === 'SuperAdmin' ?
          <>
            <Form.Item
              name="org_name"
              label={translate("Organization")}
              rules={[{ required: true, message: translate('Please select the Organization') }]}
            >
              <Select
                showSearch
                options={options}
                disabled={modalState==='Add'? false:true}
              />
            </Form.Item>
            <Form.Item
              name='role_name'
              label={translate("Role")}
              rules={[{ required: true, message: translate('Please select the role') }]}
              initialValue={"Admin"}
            >
              <Input  disabled/>
            </Form.Item>
          </>
          :
          <>
            <Form.Item
              name="role_name"
              label={translate("Role")}
              initialValue={"Member"}
            >
              <Input disabled />
            </Form.Item>
          </>
      }
    </Form>

  )
}

export default UserForm