import { getToken } from "../utils/prefix";

export async function create_org(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-org/create`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function org_getAll(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-org/orgall`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function org_getOptions(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-org/getallorg`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function org_getMember(dataInfo) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-org/member`,
    {
      body: JSON.stringify(dataInfo),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function org_list() {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/manage-org/list`, {
    body: JSON.stringify(),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function edit_org(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-org/update`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function add_member(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-org/addmember`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": getToken(),
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}
