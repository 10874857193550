import React, { useEffect, useRef, useState } from "react";
import "./style/index.less";
import { Breadcrumb, Row } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api_getVideoID, mongo_getData } from "../../../services/video";
import VideoDisplay from "./VideoDisplay";
import DetailDisplay from "./DetailDisplay";
import ModalScene from "./component/ModalScene";
import {
  subtitle_APIDownload,
  subtitle_APIGetSub,
} from "../../../services/subtitle";
import moment from "moment";
import useNotification from "../../../components/Notification";
import { LeftCircleFilled } from "@ant-design/icons";
import { get_ScenarioByID } from "../../../services/scenario";
import tiktok1 from "../../../assets/datamock/tiktok1.json";
import tiktok2 from "../../../assets/datamock/tiktok2.json";
import tiktok3 from "../../../assets/datamock/tiktok3.json";
import short1 from "../../../assets/datamock/short1.json";
import short2 from "../../../assets/datamock/short2.json";
import ads from "../../../assets/datamock/short2.json";

type Props = {};

const VideoPage = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const scrollableDiv = document.getElementById(
    "scrollableDiv"
  ) as HTMLVideoElement | null;

  //
  const [titleVideo, setTileVideo] = useState("");
  const [dataTask, setDataTask] = useState([]);
  const [dataModel, setDataModel] = useState([]);
  const [subtile, setSubtitle] = useState("");
  const [radioScenario, setRadioScenario] = useState(1);
  const [radioModel, setRadioModel] = useState<any>(null);
  const [dataScenario, setDataScenario] = useState<any>([]);
  const [seekTime, setSeekTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const { openNotification, contextHolder } = useNotification();
  //function
  const getData = async () => {
    try {
      const data = {
        task_id: params.id,
      };
      const res = await api_getVideoID(data);

      if (res.status == 200) {
        console.log("res getData:", res.data.video.status);
        //checkstatus case เข้าผ่านpathโดยตรง
        if (res.data.video.status == 5) {
          setTileVideo(res.data.video.title);
          setDataTask(res.data);
          getScenario_model(res.data.scenario_id[0].scenario_id);
        } else {
          navigate("/404");
        }
        // console.log("res getData:", res);
        // setTileVideo(res.data.video.title);
        // setDataTask(res.data);
        // getScenario_model(res.data.scenario_id[0].scenario_id);
      } else {
        openNotification("error", "Cannot get video", "");
      }
    } catch (e) {
      openNotification("error", "Cannot get video", "");
      console.error(e);
    }
  };

  const getSubtitle = async () => {
    try {
      const res = await subtitle_APIDownload({ task_id: params.id });
      const sub_path = window.webkitURL.createObjectURL(res);
      setSubtitle(sub_path);
    } catch (e) {
      console.error(e);
    }
  };

  const getScenario_model = async (id: any) => {
    try {
      const res = await get_ScenarioByID({ id: id });

      const result = res.result.model.map((d: any) => {
        return { ...d, color: "pink" };
      });
      console.log("result --", result);
      const dataModelText = result.filter(
        (i: any) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
      );
      const dataModelObject = result.filter(
        (i: any) => i.model_type == "b875130b-0492-11ee-a8f8-0242ac1b0005"
      );
      const dataModelScene = result.filter(
        (i: any) => i.model_type == "b8750eec-0492-11ee-a8f8-0242ac1b0005"
      );
      if (dataModelText.length != 0) {
        setRadioScenario(1);
      } else if (dataModelObject.length != 0) {
        setRadioScenario(2);
      } else if (dataModelScene.length != 0) {
        setRadioScenario(3);
      }
      setRadioModel({
        text: dataModelText,
        object: dataModelObject,
        scene: dataModelScene,
      });
      setDataModel(result);
    } catch (e) {
      console.error(e);
    }
  };

  const getDataResultModel = async () => {
    try {
      const res: any = await mongo_getData(params.id);
      console.log("res result ,odel", res);
      // 94661666-5b08-440f-84b3-b9b1ea2b6248 //short1
      // a08017df-fdf0-4580-9cc9-e6b3443f150c //tiktok1
      // ee964493-164c-4591-bc33-9a3a8578a3dc //tiktok2
      // 4b0b9b97-7dd6-43ff-9ce0-fcd434d45655 //tiktok3
      // const res =
      //   params.id == "a08017df-fdf0-4580-9cc9-e6b3443f150c"
      //     ? tiktok1
      //     : params.id == "ee964493-164c-4591-bc33-9a3a8578a3dc"
      //     ? tiktok2
      //     : params.id == "4b0b9b97-7dd6-43ff-9ce0-fcd434d45655"
      //     ? tiktok3
      //     : params.id == "94661666-5b08-440f-84b3-b9b1ea2b6248"
      //     ? short1
      //     : params.id == "6f15cb66-2cba-4247-bdfd-7f388fda3ff5"
      //     ? ads
      //     : short2;

      ///---///
      let alldata: any = [];
      res.object_task.map((i: any) => {
        i.annotation.map((d: any) => {
          alldata.push(d);
        });
      });

      const test = alldata.reduce((group: any, product: any) => {
        const { object } = product;
        // console.log("product", product);
        group[object] = group[object] ?? [];
        group[object].push(product);
        return group;
      }, {});

      // setObjectTag(test);

      ///---///
      console.log("res first page", res);
      // dataScenario.push(res);
      setDataScenario([res]);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getData();
    getSubtitle();
    getDataResultModel();
  }, []);

  return (
    <div className="content-container">
      <Row>
        <h1 className="header-topic" style={{ color: "#21134F" }}>
          Video
        </h1>
      </Row>
      <Row align="middle">
        <Breadcrumb
          separator=" "
          items={[
            {
              title: (
                <h3>
                  <LeftCircleFilled
                    style={{ fontSize: "16pt", color: "#21134F" }}
                    onClick={() => navigate(-1)}
                  />
                </h3>
              ),
            },
            {
              title: (
                <h3 style={{ color: "#21134F", fontSize: "14pt" }}>
                  {titleVideo}
                </h3>
              ),
            },
          ]}
        />
      </Row>
      <Row style={{ marginTop: "5px" }} gutter={[32, 16]}>
        <VideoDisplay subtile={subtile} seekTime={seekTime} endTime={endTime} />
        <DetailDisplay
          dataTask={dataTask}
          radioScenario={radioScenario}
          setRadioScenario={setRadioScenario}
          getScenario_model={getScenario_model}
          dataModel={dataModel}
          radioModel={radioModel}
          dataScenario={dataScenario}
          setSeekTime={setSeekTime}
          setEndTime={setEndTime}
        />
      </Row>
    </div>
  );
};

export default VideoPage;
