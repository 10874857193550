function getRandomTime() {
  const randomHours = Math.floor(Math.random() * 24);
  const randomMinutes = Math.floor(Math.random() * 60);
  const randomSeconds = Math.floor(Math.random() * 60);

  function formatNumber(number) {
    return number < 10 ? `0${number}` : `${number}`;
  }

  const formattedTime = `${formatNumber(randomHours)}:${formatNumber(randomMinutes)}:${formatNumber(randomSeconds)}`;
  return formattedTime;
}

const files = Math.floor(Math.random() * 100);

const cateData = [
  {
    category: 'คำหยาบคาย',
    value: 10
  },
  {
    category: 'โฆษณาเกินเกณฑ์',
    value: 10
  },
  {
    category: 'โฆษณาเกินจริง',
    value: 10
  },
  {
    category: 'อื่น ๆ',
    value: 3
  }
]

const pieData = [{
  category: 'Detected',
  value: Math.floor(Math.random() * 50)
},
{
  category: 'Undetected',
  value: Math.floor(Math.random() * 50)
}]

function addRandomTime(data) {
  if(data !== null){
    data.forEach((item) => {
      const hours = Math.floor(item.value / 3600);
      const minutes = Math.floor((item.value % 3600) / 60);
      const seconds = item.value % 60;
      item.timeFormatted = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    });
    return data;
  }else{
    return null;
  }
}

export {
  getRandomTime,
  addRandomTime,
  files,
  cateData,
  pieData
}