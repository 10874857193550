import { useEffect, useRef, useState } from "react";
import "./style/index.less";
import { Popover } from "antd";

export const Container = ({ children }) => (
  <div className="tag-container">{children}</div>
);

const Tag = ({ children, color }) => (
  <span style={{ backgroundColor: color }} className="tag">
    {children}
  </span>
);

export default function TagList({ parentWidth, tags, setSearchValue}) {
  const [lastDisplayedIndex, setLastDisplayedIndex] = useState(-1);
  const list = useRef(null);
  const moreTag = useRef(null)
  const content = useRef();

  useEffect(() => {
    const fullListWidth = list.current.offsetWidth;
    if (fullListWidth > parentWidth && tags) {
      const childrenDOMNodes = Array.from(list.current.childNodes);
      const childrenBoundingClientRect = childrenDOMNodes.map((child) =>
        child.getBoundingClientRect()
      );

      let childrenWidth = 0;
      let lastIndex = -1;
      for (let i = 0; i < childrenBoundingClientRect.length; i++) {
        childrenWidth += childrenBoundingClientRect[i].width + 5;
        if (childrenWidth >= parentWidth) {
          lastIndex = i-1;
          break;
        }
      }

      moreTag.current = tags?.map((item, i) => {
        if (i >= lastIndex) {
          return <Tag key={i} color={item.color}>
            {item.name}
          </Tag>
        }
      });

      content.current = 
        <div className="more-list" style={{maxHeight:'200px',display:'flex',flexDirection:"column",overflow:'auto',overflowX:'hidden',rowGap:'5px'}}>
        {moreTag.current}
      </div>

      setLastDisplayedIndex(lastIndex);
    } else {
      setLastDisplayedIndex(-1);
    }
  }, [parentWidth, tags]);

  return (
    <div  style={{ position: "relative", overflow: "hidden" }}>
      <div className="tag-list">
        {tags === null ? <span className="tag empty">No Tag</span>:<>
          {lastDisplayedIndex === -1
            ? tags?.map((tag, i) => (
              <Tag key={i} color={tag.color}>
                {tag.name}
              </Tag>
            ))
            : tags
              ?.slice(0, lastDisplayedIndex)
              .map((tag, i) => (
                <Tag key={i} color={tag.color} onClick={()=>setSearchValue(tag.name)}>
                  {tag.name}
                </Tag>
              ))}
          {lastDisplayedIndex !== -1 && (
            <Popover
              placement="leftTop"
              title={""}
              content={content.current}
              trigger="click"
            >
              <span className="tag more">{`+${tags.length - lastDisplayedIndex}`}</span>
            </Popover>
          )}</>}
      </div>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          visibility: "hidden",
        }}
        ref={list}
      >
        {tags != null && <>{tags?.map((tag, i) => (
          <Tag key={i} color={tag.color}>{tag.name}</Tag>
        ))}</>}
        
      </div>
    </div>
  );
}