import { Button, Col, Progress, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { subtitle_APIDownload } from "../../../services/subtitle";
import "./style/index.less";
import config from "../../../config/config";

type Props = {
  subtile: any;
  seekTime: any;
  endTime: any;
};

const VideoDisplay = (props: Props) => {
  const params = useParams();
  const [timingVideo, settimingVideo] = useState<any>(null);
  let myVideo: any = document.getElementById("video");

  const timeUpdate = () => {
    try {
      if (myVideo && props.endTime != null) {
        console.log("props.endTime", props.endTime);
        console.log("props.seekTime", props.seekTime);
        if (myVideo.currentTime >= props.endTime) {
          myVideo.currentTime = props.seekTime;
          myVideo.pause();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // getData();
  }, []);

  return (
    <Col md={24} lg={12}>
      <Row>
        <Col xs={24}>
          <video
            id="video"
            preload="metadata"
            width="100%"
            height="100%"
            controls
            onTimeUpdate={timeUpdate}
          >
            <source
              src={`${process.env.REACT_APP_VIDEO_PATH}${params.id}`}
              type="video/mp4"
            />
            <track
              label="English"
              kind="subtitles"
              src={props.subtile}
              default
            />
          </video>
        </Col>
      </Row>
      <Row
        justify={"space-between"}
        align={"middle"}
        style={{ position: "relative" }}
      >
        <Col
          style={{
            position: "absolute",
            top: "10px",
            left: "0",
          }}
        >
          {/* <Row gutter={16}>
            <Col>
              <Button onClick={() => ""} type="primary">
                Delete
              </Button>
            </Col>
          </Row> */}
        </Col>
        <Col>
          {/* <div
            style={{
              padding: "8px 8px",
              background: "#D9D9D9",
              borderRadius: "4px",
            }}
          >
            {timingVideo}
          </div> */}
        </Col>
      </Row>
    </Col>
  );
};

export default VideoDisplay;
