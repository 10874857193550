import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { route } from "./routes/routes";
import { AuthWrapper, useAuth } from "./utils/Auth/AuthWrapper";
import ProtectedRoute from "./utils/Auth/ProtectedRoute";
import DefaultLayout from "./layout/defualtLayout";
import LoginPage from "./views/login";
import Unauthorized from "./components/Unauthorized";
import LandingPage from "./layout/LandingPage";
import {ConfigProvider} from 'antd'

function App() {
  return (
      <Router basename={`${process.env.REACT_APP_BASE_PATH}`}>
        <ConfigProvider theme={{
  token: {
    fontFamily: 'Sukhumvit-Set',

  },
}}>
        <AuthWrapper>
          <Routes  >
            <Route
              path="/*"
              // element={<Navigate to="/dashboard" replace={true} />}
              element={<LandingPage/>}
              // element={<DefaultLayout />}
            />
            {/* <Route path="/404" element={<DefaultLayout />} /> */}

            {/* <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/500" element={<>500</>} /> */}
            <Route path="/user/login" element={<LoginPage />} />
            <Route path="/user/registration" element={<LoginPage />} />
            <Route element={<DefaultLayout />}>
              {route.map((routes, ind) => {
                if (routes.routes) {
                  return(
                    <Route key={routes.name}>
              
                    {routes.routes.map((child, inds) => {
                      return (
                        <Route
                          key={child.name}
                          path={routes.path+child.path}
                          element={
                            <ProtectedRoute access={routes.access}>
                             {child.component}
                            </ProtectedRoute>
                          }
                        
                        />
                      );
                    })}
                 </Route>
                  )
                }else{
                  return (
                    <Route
                      key={routes.name}
                      path={routes.path}
                      element={ 
                      <ProtectedRoute access={routes.access}>
                        {routes.component}
                      </ProtectedRoute>
                    }
                    />
                  );
                }
              })}
            </Route>
            {/* <Route path="*" element={<Navigate to="/404" replace={true} />} /> */}
          </Routes>
        </AuthWrapper>
        </ConfigProvider>
      </Router>

  );
}

export default App;
