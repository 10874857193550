export async function getSetting() {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-setting/getsetting`,
    {
      method: "POST",
      headers: {
        "x-access-token": process.env.REACT_APP_TOKEN_BACKEND,
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function setSetting(data) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-setting/setSettings`,
    {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        "x-access-token": process.env.REACT_APP_TOKEN_BACKEND,
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}

export async function manualBackup() {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/manage-setting/execBackup`,
    {
      method: "POST",
      headers: {
        "x-access-token": process.env.REACT_APP_TOKEN_BACKEND,
        "Content-type": "application/json",
      },
    }
  );

  const dataRespone = await res.json();
  return dataRespone;
}
