import { DownOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, MenuProps, Row, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Highlight } from "./Highlight";

type Props = {
  dataModel: any;
  dataScenario: any;
  selectModelText: any;
  setSelectModelText: any;
  selectTags: any;
  setSelectTags: any;
};

const Text = ({
  dataModel,
  dataScenario,
  selectModelText,
  setSelectModelText,
  selectTags,
  setSelectTags,
}: Props) => {
  // const [data, setData] = useState<any>([]);
  // const [selectTags, setSelectTags] = useState("");
  // const [modelTags, setModelTags] = useState<any>([]);
  // let resultDataText: any = [];

  const columns: ColumnsType<any> = [
    // {
    //   title: "Time",
    //   dataIndex: "time",
    //   key: "time",
    //   align: "center",
    //   width: "25%",
    //   // render: (text) => <a>{text}</a>,
    // },
    // {
    //   title: "Relevance (%)",
    //   dataIndex: "relevance",
    //   key: "relevance",
    //   align: "center",
    //   width: "25%",
    // },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
      width: "20%",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      align: "center",
      width: "20%",
    },
    {
      title: <Row justify="end"></Row>,
      dataIndex: "text",
      key: "text",
      render: (_: any, row: any) => {
        // console.log("text model", row.model_name);
        let textHighlight: any = [];
        const resultTxt = row.model_name
          .filter((item: any) => {
            // modelTags.push(item.name);
            console.log("filter item", item);

            if (selectTags == "") {
              if (item.highlight.length != 0) {
                const res = item.highlight.map((d: any) => {
                  return d;
                });
                return res;
              }
            } else if (item.name == selectTags) {
              if (item.highlight.length != 0) {
                const res = item.highlight.map((d: any) => {
                  return d;
                });
                return res;
              }
            }
          })
          .map((d: any) => {
            const res = d.highlight.map((item: any) => {
              console.log("item map push", item);

              textHighlight.push(item);
              return item;
            });
            // console.log("res", res);
            return res;
          });
        // console.log("textHighlight", textHighlight);

        // return <Highlight tags={row.highlight}>{row.text}</Highlight>;
        return (
          <Highlight
            tags={textHighlight.sort(
              (a: any, b: any) => a.being_char - b.being_char
            )}
          >
            {row.text}
          </Highlight>
        );
      },
    },
  ];

  const SecToTime = (timeInSeconds: any) => {
    let time: any = parseFloat(timeInSeconds).toFixed(3);
    let hours = Math.floor(time / 60 / 60);
    let minutes = Math.floor(time / 60) % 60;
    let seconds = Math.floor(time - minutes * 60);
    let milliseconds = time.slice(-3);

    return (
      pad(hours, 2) +
      ":" +
      pad(minutes, 2) +
      ":" +
      pad(seconds, 2) +
      "." +
      pad(milliseconds, 3)
    );
  };
  const pad = (num: any, size: any) => {
    return ("000" + num).slice(size * -1);
  };
  // console.log("dataScenario::", dataScenario);

  //###current
  // const res: any = dataScenario[0]?.text_subtitle.map((d: any, ind: number) => {
  //   console.log("d", d);
  //   console.log("ind", ind);
  //   return {
  //     id: ind + 1,
  //     time: SecToTime(Number(d.start_time / 1000)),
  //     // time: d.start_time,

  //     relevance: d.model_name[0].score * 100,
  //     text: d.text,
  //     highlight: d.highlight,
  //   };
  // });
  ///###new
  // console.log("dataScenario out", dataScenario);
  // const getData = async () => {
  //   console.log("dataScenario", dataScenario);
  let modelTags: any = [];
  const res: any = dataScenario[0]?.text_subtitle.map((d: any, ind: number) => {
    return {
      id: d.sub_id,
      time: SecToTime(Number(d.start_time / 1000)),
      start_time: SecToTime(Number(d.start_time / 1000)),
      end_time: SecToTime(Number(d.end_time / 1000)),
      // time: d.start_time,
      model_name: d.model_name,
      // relevance: d.model_name[0].score * 100,
      text: d.text,
      // highlight: d.highlight,
    };
  });
  // console.log("res res ", res);
  // setData(res);
  // resultDataText.push(res);
  // };

  const dataModelText = dataModel.filter(
    (i: any) => i.model_type == "b875162b-0492-11ee-a8f8-0242ac1b0005"
  );
  let alldata: any = [];
  dataScenario[0]?.text_subtitle.map((i: any) => {
    i.model_name.map((d: any) => {
      alldata.push(d);
    });
  });
  const tagObject = alldata.reduce((group: any, product: any) => {
    const { name } = product;
    console.log("productproduct", product);
    group[name] = group[name] ?? [];
    group[name].push({ value: product.highlight.length });
    return group;
  }, {});
  console.log("tagObject", tagObject);

  const calculateSum = (array: any, property: any) => {
    const total = array.reduce((accumulator: any, object: any) => {
      return accumulator + object[property];
    }, 0);

    return total;
  };

  // console.log("modelTags", modelTags);

  useEffect(() => {
    // getData();
  }, []);

  return (
    <Row>
      <Col xs={24}>
        <Space size={[0, 8]} wrap>
          {dataModelText.map((d: any) => {
            console.log("d", d);
            return (
              <Tag
                key={d.model_id}
                color={selectModelText != d.model_name ? d.color : "#c41d52"}
                // color={"red"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectModelText(d.model_name);
                  setSelectTags(d.model_name);
                }}
              >
                {d.text} (
                {tagObject[d.model_name] == undefined
                  ? 0
                  : calculateSum(tagObject[d.model_name], "value")}
                )
              </Tag>
            );
          })}
        </Space>
      </Col>
      <Col xs={24}>
        <Row>
          {Array(5).map((d: any) => {
            return <Col xs={6}>test</Col>;
          })}
        </Row>
      </Col>
      <Col xs={24} style={{ marginTop: "10px" }}>
        <Table
          rowKey="id"
          columns={columns}
          // dataSource={data}
          dataSource={res}
          pagination={false}
          scroll={{ y: 320 }}
        />
      </Col>
    </Row>
  );
};

export default Text;
