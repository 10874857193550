import { getToken } from "../utils/prefix";

export async function user_login(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/user/login`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function user_register(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/user/register`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });

  const dataRespone = await res.json();
  return dataRespone;
}

export async function get_profile() {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/user/profile`, {
    body: JSON.stringify(),
    method: "POST",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });
  const dataRespone = await res.json();
  return dataRespone;
}
export async function edit_profile(data) {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/user/edit`, {
    body: JSON.stringify(data),
    method: "PUT",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });
  const dataRespone = await res.json();
  return dataRespone;
}

export async function upload_profilepic(formData) {
  const res = await fetch(
    process.env.REACT_APP_BACKEND + `/user/uploadprofile`,
    {
      body: formData,
      method: "POST",
      headers: {
        "x-access-token": getToken(),
      },
    }
  );
  const dataRespone = await res.json();
  return dataRespone;
}

export async function check_UserStatus() {
  const res = await fetch(process.env.REACT_APP_BACKEND + `/user/checkstatus`, {
    method: "GET",
    headers: {
      "x-access-token": getToken(),
      "Content-type": "application/json",
    },
  });
  const dataRespone = await res.json();
  return dataRespone;
}
