import React from "react";

export const Highlight = ({ children: text = "", tags = [] }: any) => {
  if (!tags?.length) return text;
  // if (tags == undefined) return text;
  const matches = [...text.matchAll(new RegExp(tags.join("|"), "ig"))];
  // console.log("tags:", tags);
  // const startText = text.slice(0, matches[0]?.index);
  const startText = text.slice(0, tags[0]?.being_char);
  return (
    <span>
      {startText}
      {/* {matches.map((match, i) => { */}
      {tags.map((match: any, i: any) => {
        if (
          i != 0 &&
          tags[i]?.being_char > tags[i - 1]?.being_char &&
          tags[i]?.end_char < tags[i - 1]?.end_char
        ) {
          // console.log("match", match);
          const startIndex = match.being_char;
          // const currentText = match[0];
          // const endIndex = startIndex + currentText.length;
          const endIndex = match.end_char;
          const nextIndex = tags[i + 1]?.being_char;
          const untilNextText = text.slice(endIndex, nextIndex);
          // console.log("startIndex", startIndex);
          // console.log("nextIndex", nextIndex);
          // console.log("untilNextText", untilNextText);
          return <span key={i}>{untilNextText}</span>;
        } else {
          // console.log("match", match);
          const startIndex = match.being_char;
          // const currentText = match[0];
          // const endIndex = startIndex + currentText.length;
          const endIndex = match.end_char;
          const nextIndex = tags[i + 1]?.being_char;
          const untilNextText = text.slice(endIndex, nextIndex);
          // console.log("startIndex", startIndex);
          // console.log("nextIndex", nextIndex);
          // console.log("untilNextText", untilNextText);
          return (
            <span key={i}>
              <mark style={{ background: "#FFFBA4" }}>{match.token}</mark>
              {/* <mark style={{ background: "#f2ed83" }}>{match.token}</mark> */}
              {untilNextText}
            </span>
          );
        }
      })}
    </span>
  );
};

// export const Highlight = ({ children: text = "", tags = [] }: any) => {
//   if (!tags?.length) return text;
//   const matches = [...text.matchAll(new RegExp(tags.join("|"), "ig"))];
//   console.log("matches:", matches);
//   const startText = text.slice(0, matches[0]?.index);
//   return (
//     <span>
//       {startText}
//       {matches.map((match, i) => {
//         const startIndex = match.index;
//         const currentText = match[0];
//         const endIndex = startIndex + currentText.length;
//         const nextIndex = matches[i + 1]?.index;
//         const untilNextText = text.slice(endIndex, nextIndex);
//         return (
//           <span key={i}>
//             <mark style={{ background: "#f2ed83" }}>{currentText}</mark>
//             {untilNextText}
//           </span>
//         );
//       })}
//     </span>
//   );
// };
