import React, { useEffect, useState } from "react";
import {
  checkReadNotification,
  selectedNotification,
} from "../../services/notification";
import {
  Badge,
  Popover,
  List,
  Typography,
  Row,
  Col,
  Dropdown,
  Button,
  Divider,
  Skeleton
} from "antd";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
moment.updateLocale("th", {
  day: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    ,
    "Thursday",
    "Friday",
    "Saturday",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Today at] LT",
    lastWeek: "[Last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "dddd DD [at] LT",
  },
});



const NotificationPage = (props) => {
  const [data, setData] = useState([]);
  const [btnSelect, setBtnSelect] = useState(1);
  const [open, setOpen] = useState(false);
  const [pages,setPages] = useState(1)
  const [total,setTotal] = useState(10)
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const res = await selectedNotification({
        page: pages,
        size: 10,
        check_status: btnSelect == 2 ? 1 : "all",
      });
        setData([...data,...res.data]);
        setTotal(res.meta.total)

    } catch (e) {
      console.error(e);
    }
  };




  useEffect(() => {
    getData();
  }, [pages,btnSelect]);

  return (
    <div>
      <Row style={{ padding: "20px 15rem" }} justify={"center"}>
        <Col  sm={20} style={{
            backgroundColor: "white",
            padding: "10px 35px",
            borderRadius: "10px",
          }}>
            <Row style={{marginBottom:"1rem"}} align={"middle"} justify={"space-between"}>
            <Col  style={{ fontSize: "16pt" }}>Notification</Col>
            <Col>
            <Row gutter={8}>
                  <Col>
                    <Button
                      onClick={() => {
                        setData([]);
                        setPages(1)
                        setBtnSelect(1);
                      }}
                      style={{ borderRadius: "20px" }}
                      type={btnSelect == 1 ? "primary" : "default"}
                    >
                      All
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        setData([]);
                        setPages(1)
                        setBtnSelect(2);
                      }}
                      style={{ borderRadius: "20px" }}
                      type={btnSelect == 2 ? "primary" : "default"}
                    >
                      Unread
                    </Button>
                  </Col>
                </Row>
          </Col>
            </Row>
            <Row justify={"center"}>
            <Col
          sm={20}
          id="scrollableDiv"
          style={{
            backgroundColor: "white",
            padding: "10px 35px",
            borderRadius: "10px",
            height:"80dvh",
            overflow: 'auto',
            padding: '0 16px',
          }}
        >
        <InfiniteScroll
          dataLength={data.length}
          next={()=>{
            setPages(pages + 1)
 
          }}
          hasMore={data.length < total}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<Divider plain></Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                style={item.status == 1 ? { backgroundColor: "#E6F4FF" } : {}}
                className="notification-list"
                onClick={async () => {
                  const res = await checkReadNotification({
                    id_status: item.id,
                    st: 2,
                  });
                  if (res.status == 200) {
                    if (btnSelect == 1) {
                    } 
                    else if (btnSelect == 2) {
                    }
                  }
                  if (item.link_to != null) {
                    setOpen(false);
                    navigate(`${item.link_to}`);
                  }
                }}
              >
                {/* <Typography.Text mark>[ITEM]</Typography.Text>  */}
                <Row>
                  <Col style={{ fontSize: "10pt" }} xs={24}>
                    {item.content}
                  </Col>
                  <Col style={{ fontSize: "8pt" }} xs={24}>
                    {moment(item.timestamp).fromNow()}
                  </Col>
                </Row>
              </List.Item>
            )}
            className="notification-scroll"
          />
          </InfiniteScroll>
        </Col>
            </Row>
        </Col>
       
       
      </Row>
    </div>
  );
};

export default NotificationPage;
