import {
  Badge,
  Popover,
  List,
  Typography,
  Row,
  Col,
  Dropdown,
  Button,
} from "antd";
import { useState, useEffect } from "react";
import { BellOutlined } from "@ant-design/icons";
import { MoreOutlined } from "@ant-design/icons";
import "./style/index.less";
import { timeStamp } from "console";
import moment from "moment";
import {
  checkReadAllNotification,
  checkReadNotification,
  countNotificationUnRead,
  selectedNotification,
} from "../services/notification";
import { useSocket } from "../utils/socketContext/socketProvider";
import { useAuth } from "../utils/Auth/AuthWrapper";
import { useNavigate } from "react-router-dom";
moment.updateLocale("th", {
  day: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    ,
    "Thursday",
    "Friday",
    "Saturday",
  ],
  weekdays: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Today at] LT",
    lastWeek: "[Last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "dddd DD [at] LT",
  },
});

type Props = {};

const NotiMessage = (props: Props) => {
  const [data, setData] = useState<any>([]);
  const [btnSelect, setBtnSelect] = useState(1);
  const [socketUpdate, setSocketUpdate] = useState(false);
  const socket = useSocket();
  const auth = useAuth();
  const navigate = useNavigate();
  const [countNoti, setCountNoti] = useState(0);
  const [open, setOpen] = useState(false);

  const getData = async (value?: any) => {
    try {
      const res = await selectedNotification({
        page: 1,
        size: 6,
        check_status: value ? value : "all",
      });
      console.log("res notification ::", res);

      // res.meta page:1,size:10,total:4
      setData(res.data);
      setSocketUpdate(false);
      const res_count = await countNotificationUnRead();
      setCountNoti(res_count.data?.length);
    } catch (e) {
      // localStorage.clear();
      console.error(e);
    }
  };

  const handleSetReadAll = async () => {
    try {
      // const res = data.map((item: any) => {
      //   return { ...item, status: 2 };
      // });
      // setData(res);
      const res = await checkReadAllNotification();
      console.log("Res checkall::", res);
      if (res.status == 200) {
        getData();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const items = {
    items: [
      {
        key: "1",
        label: (
          <p onClick={handleSetReadAll}>{"Mark as read all"}</p>
        ),
        //   icon: <UserOutlined onClick={handleProfile} />,
      },
      {
        key: "2",
        label: (
          <p onClick={() => navigate("/notification")}>{"Open Notification"}</p>
        ),
        //   icon: <UserOutlined onClick={handleProfile} />,
      },
    ],
  };

  const socketFunction = async () => {
    socket.socket.on("notification", (data) => {
      if (auth.user.org_id == data) {
        setSocketUpdate(true);
      }
    });
  };

  useEffect(() => {
    getData();
  }, [socketUpdate == true]);

  useEffect(() => {
    socketFunction();
  }, [socket.socket]);

  return (
    <div style={{ padding: "0px 0px 0px 5px", marginTop: "5px" }}>
      <Popover
        content={
          <List
            header={
              <>
                <Row justify="space-between" align="middle">
                  <Col style={{ fontSize: "14pt" }}>Notification</Col>
                  <Col>
                    <Dropdown
                      overlayClassName="header-menu-dropdown"
                      menu={items}
                      placement="bottomRight"
                      trigger={["click"]}
                    >
                      <MoreOutlined rotate={90} />
                    </Dropdown>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col>
                    <Button
                      onClick={() => {
                        // setBtnSelect(1);
                        getData();
                        setBtnSelect(1);
                      }}
                      style={{ borderRadius: "20px" }}
                      type={btnSelect == 1 ? "primary" : "default"}
                    >
                      All
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        // const res = data.filter((item: any) => {
                        //   if (item.status == 1) {
                        //     return item;
                        //   }
                        // });
                        // setData(res);
                        getData(1);
                        setBtnSelect(2);
                      }}
                      style={{ borderRadius: "20px" }}
                      type={btnSelect == 2 ? "primary" : "default"}
                    >
                      Unread
                    </Button>
                  </Col>
                </Row>
              </>
            }
            footer={
              <Row justify="center" align="middle">
                <Col
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => navigate("/notification")}
                >
                  See more.
                </Col>
              </Row>
            }
            // bordered
            style={{ minWidth: "300px", maxWidth: "300px" }}
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item
                style={item.status == 1 ? { backgroundColor: "#E6F4FF" } : {}}
                className="notification-list"
                onClick={async () => {
                  const res = await checkReadNotification({
                    id_status: item.id,
                    st: 2,
                  });
                  if (res.status == 200) {
                    if (btnSelect == 1) {
                      console.log("click all");
                      getData();
                    } else if (btnSelect == 2) {
                      console.log("click not read");
                      getData(2);
                    }
                  }
                  if (item.link_to != null) {
                    setOpen(false);
                    navigate(`${item.link_to}`);
                  }
                }}
              >
                {/* <Typography.Text mark>[ITEM]</Typography.Text>  */}
                <Row>
                  <Col style={{ fontSize: "10pt" }} xs={24}>
                    {item.content}
                  </Col>
                  <Col style={{ fontSize: "8pt" }} xs={24}>
                    {moment(item.timestamp).fromNow()}
                  </Col>
                </Row>
              </List.Item>
            )}
            className="notification-scroll"
          />
        }
        // title="Notification"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Badge
          size="small"
          // count={data.filter((item: any) => item.status == 1).length}
          count={countNoti}
        >
          <BellOutlined className="bell-noti" style={{ fontSize: "20px" }} />
        </Badge>
      </Popover>
    </div>
  );
};

export default NotiMessage;
